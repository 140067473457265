<template>
  <div class="author">
    <div
      class="author-img"
      :class="{
        'author-live-img': tab == 1,
        'author-group-img': tab == 2,
        'author-friend-img': tab == 3,
        'assist-friend-img': tab == 4
      }"
      v-if="item"
    >
      <img
        v-if="item.joinMap && item.joinMap.u && item.joinMap.u.avatar"
        :src="item.joinMap && item.joinMap.u && item.joinMap.u.avatar | url"
        class="room-name-pic"
      />
      <span v-else class="author-text">{{
        item.name && item.name.trim().charAt(0)
      }}</span>
      <span
        class="iconfont icon-jinyan author-icon"
        v-if="item.mute == 1 && tabActive == 3"
      ></span>
      <span
        class="iconfont icon-dongjie author-icon author-dongjie"
        v-if="
          item.joinMap &&
          item.joinMap.u &&
          item.joinMap.u.cancel == 1 &&
          tabActive == 4
        "
      ></span>
    </div>
    <span v-if="isDraw" class="iconfont icon-draw"></span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      }
    },
    tab: {
      type: Number,
      default: 1
    },
    tabActive: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    isDraw() {
      let r = this.$store.state.drawMap[this.item.id];
      if (this.item.messageType == 2) {
        r = this.$store.state.drawMap[this.item.groupId];
      }
      if (this.item.messageType == 3) {
        r = this.$store.state.drawMap[this.item.friendId];
      }
      if (this.item.messageType == 4) {
        let key = `${this.item.messageType}-${this.item.friendId}`;
        r = this.$store.state.drawMap[key];
      }
      if (!r || !r.id) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="less" scoped="scoped">
.author {
  position: relative;
}
.author-img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #ffa800;
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 998;
}
.author-live-img {
  background-color: #ffa800;
}
.author-group-img {
  background-color: #1bc5bc;
}
.author-friend-img {
  background-color: #459bff;
}
.assist-friend-img {
  background-color: #67c23a;
}
.author-img img {
  width: 48px;
  height: 48px;
  position: relative;
  z-index: 998;
}
.author-icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  font-size: 38px;
  color: #ff6060;
  z-index: 999;
}
.author-dongjie {
  font-size: 35px;
}
.author-text {
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  position: relative;
  z-index: 998;
}
.icon-draw {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  z-index: 999;
}
</style>
