<template>
  <div
    class="agmsg"
    v-if="talking1 && talking1.id && $store.state.user && $store.state.user.id"
    @click="
      isrigh = '';
      iconshow = false;
    "
    @mouseleave="onmouseout"
  >
    <div
      class="agmsg-top"
      :class="{
        group: talking1.messageType == 2,
        friend: talking1.messageType == 3,
        assist: talking1.messageType == 4
      }"
    >
      <div class="agmsg-top-name">
        <div class="agmsg-top-left">
          <div class="ag-msg-type live" v-if="talking1.messageType == 1">
            <span class="ag-msg-type-text">直播间</span>
          </div>
          <div class="ag-msg-type group" v-if="talking1.messageType == 2">
            <span class="ag-msg-type-text">群组</span>
          </div>
          <div class="ag-msg-type friend" v-if="talking1.messageType == 3">
            <span class="ag-msg-type-text">私聊</span>
          </div>
          <div class="ag-msg-type assist" v-if="talking1.messageType == 4">
            <span class="ag-msg-type-text">小助手</span>
          </div>
          <span
            class="agmsg-top-text"
            v-if="talking1.messageType != 3 && talking1.messageType != 4"
            >{{ talking1.name }}</span
          >
          <span class="agmsg-top-text" v-else>{{
            getUserName(talking1, talking1.friendId)
          }}</span>
        </div>
        <div class="agmsg-top-right">
          <img
            class="agmsg-top-right-img"
            src="../assets/svg/draw.svg"
            @click="ondraw"
          />
          <img
            class="agmsg-top-right-img"
            src="../assets/svg/yonghu.svg"
            @click="onInfoDetail"
          />
        </div>
      </div>
      <div class="agmsg-top-bottom">
        <div class="agmsg-top-bottom-live" v-if="talking1.messageType == 1">
          <span class="agmsg-top-bottom-text"
            >{{
              talking1.joinMap &&
              talking1.joinMap.match &&
              talking1.joinMap.match.nameAbbr
            }}
            -</span
          >
          <span class="agmsg-top-bottom-text"
            >{{
              talking1.joinMap &&
              talking1.joinMap.schedule &&
              talking1.joinMap.schedule.teamNamea
            }}
            VS
            {{
              talking1.joinMap &&
              talking1.joinMap.schedule &&
              talking1.joinMap.schedule.teamNameb
            }}</span
          >
        </div>
        <div class="agmsg-top-bottom-live" v-if="talking1.messageType == 2">
          <span class="agmsg-top-bottom-text"
            >{{
              talking1.joinMap &&
              talking1.joinMap.g &&
              talking1.joinMap.g.amount
            }}
            成员</span
          >
        </div>
        <div
          class="agmsg-top-bottom-live"
          v-if="talking1.messageType == 3 || talking1.messageType == 4"
        >
          <div
            class="friend-state"
            :class="{ notline: !getUserOnLine(talking1.friendId) }"
          ></div>
          <span class="agmsg-top-bottom-text">{{
            getUserOnLine(talking1.friendId) ? '在线' : '离线'
          }}</span>
          <span
            class="agmsg-top-bottom-remark"
            v-if="talking1 && talking1.remark && talking1.remark != undefined"
            >来自<span
              class="agmsg-top-bottom-zbremark"
              :class="{
                'agmsg-top-bottom-zbremark-assist': talking1.messageType == 4
              }"
              >{{ talking1 && talking1.remark }}</span
            >直播间</span
          >
        </div>
      </div>
    </div>
    <div
      class="agmsg-body"
      :class="{ advertisement: active == 'advertisement' }"
    >
      <div
        class="agmsg-content"
        @scroll="showMoreMessage"
        ref="msglist"
        v-infinite-scroll="loadFootMore"
      >
        <el-divider class="divider-more">
          <span
            @click="prevMsg"
            style="cursor: pointer"
            class="divider-text"
            v-if="!prevMsging"
            >查看更多消息</span
          >
          <span style="cursor: pointer" class="divider-text" v-if="prevMsging"
            >数据正在加载，请稍后。。。</span
          >
        </el-divider>
        <!-- MAL-2635 n && n.type !== vars.TYPE_BANG 主播私聊綁定電話後訊息不顯示 -->
        <div
          class="agmsg-content-body"
          :class="{
            msgLeft: msgLeft(n),
            msgRight: !msgLeft(n),
            notice: n.notice == 2
          }"
          :id="'v' + indexMsg + '-' + n.id"
          v-for="(n, index) in messageList4"
          :key="n.messageType + '-' + n.id"
          v-if="n && n.type !== vars.TYPE_BANG"
        >
          <div
            class="agmsg-body-menu"
            :style="{ top: menuTop + 'px', left: menuLeft + 'px' }"
            v-if="isrigh == n.id"
          >
            <div class="agmsg-body-menu-item" @click="onPerInfo(n)">
              个人资讯
            </div>
            <div
              class="agmsg-body-menu-item"
              :class="{ 'not-allowed': talking1.messageType == 3 }"
              @click="siliao($event, n)"
            >
              私聊
            </div>
            <div class="agmsg-body-menu-item" @click="addgroup(n)">加群</div>
            <div
              class="agmsg-body-menu-item"
              v-if="talking1.messageType != 2"
              :class="{ 'not-allowed': talking1.messageType != 1 }"
              @click="mute($event, n)"
            >
              {{
                getUserMute(talking1, n.senderId).mute == 1 ? '解禁' : '禁言'
              }}
            </div>
            <div class="agmsg-body-menu-item" v-else @click="mute($event, n)">
              {{
                getUserMute(talking1, n.senderId).muted == 1 ? '解禁' : '禁言'
              }}
            </div>
            <div class="agmsg-body-menu-item" @click="userCancel(n)">
              {{ getUserCancel(n.senderId) == 1 ? '解冻' : '冻结' }}
            </div>
          </div>
          <el-divider v-if="n.id == divider.id">
            <span class="divider-text">未读取的信息</span>
          </el-divider>
          <el-divider
            class="invitation-divider"
            v-if="
              n.type == vars.TYPE_ENTER &&
              talking1.messageType == 2 &&
              getJsonContent(n.refContent)
            "
          >
            <div class="invitation-message">
              <span class="invitation-text">{{
                n.senderName ||
                (n.joinMap && n.joinMap.u && n.joinMap.u.userNicename)
              }}</span
              >邀请<span class="invitation-text">{{
                getJsonContent(n.refContent)
              }}</span
              >加入了群聊
            </div>
          </el-divider>
          <div
            class="agmsg-content-body-item"
            v-if="n && n.type !== vars.TYPE_BANG"
          >
            <div
              class="agmsg-body-item-img"
              v-if="msgLeft(n)"
              @click.stop="onRight($event, n)"
              @click.right="onRight($event, n)"
            >
              <img
                class="ag-msg-item-img"
                v-if="n.joinMap && n.joinMap.u && n.joinMap.u.avatar"
                :src="n.joinMap.u && n.joinMap.u.avatar | url"
              />
              <div v-else class="ag-msg-item-img">
                <span>{{
                  n.joinMap &&
                  n.joinMap.u &&
                  n.joinMap.u.userNicename &&
                  n.joinMap.u.userNicename.charAt(0)
                }}</span>
              </div>
            </div>
            <div class="agmsg-body-item-content" @click="ondel(n)">
              <div class="agmsg-body-item-name">
                <div class="agmsg-body-item-name-text" v-if="msgLeft(n)">
                  {{ getUserName(n, n.senderId) }}
                </div>
                <div
                  class="agmsg-body-item-time"
                  v-if="
                    n.type != vars.TYPE_ENTER ||
                    talking1.messageType == 3 ||
                    talking1.messageType == 4
                  "
                >
                  {{ time(n.addTime || n.createTime) }}
                </div>
              </div>
              <div class="agmsg-body-item-type">
                <span
                  class="iconfont icon-share1 agmsg-body-item-share"
                  :class="{ 'group-share': talking1.messageType == 2 }"
                  v-if="!msgLeft(n) && n.contentType == 3"
                  @click.stop="shareImage(n)"
                ></span>
                <span
                  class="iconfont icon-del agmsg-body-item-del"
                  v-if="!msgLeft(n) && delId == n.id"
                  :class="{ right: !msgLeft(n) }"
                  @click.stop="delMsg(n, index)"
                ></span>
                <span
                  v-if="
                    !msgLeft(n) &&
                    (talking1.messageType == 3 || talking1.messageType == 4)
                  "
                  class="agmsg-body-item-status"
                >
                  {{ getReadMsg(n.msgId, n.status) }}</span
                >
                <div v-if="!n.jsonContent && n.contentType != 3">
                  <div
                    class="agmsg-body-item-text"
                    v-if="n.type == 1"
                    v-html="n.content"
                    style="white-space: pre-wrap"
                  ></div>
                  <div
                    class="agmsg-body-item-text"
                    v-if="n.contentType == 1 && n.type != 1"
                  >
                    {{ n.content }}
                  </div>
                </div>
                <!-- 图片 -->
                <el-image
                  v-if="n.contentType == 3"
                  v-loading="loadImg == n.id"
                  class="agmsg-body-item-char-img"
                  :key="n.content"
                  :src="n.content | image"
                  lazy
                  @click="ondialogEnlargeImage(n.content)"
                  @load="loadingImg"
                >
                </el-image>
                <div
                  class="json-content"
                  v-if="n.jsonContent && !n.thumb"
                  @longpress="onTextTap(n)"
                >
                  <div
                    v-for="(ji, index) in n.jsonContent"
                    :key="'jc' + n.id + '-' + index"
                  >
                    <div v-if="ji.type == 'text'">
                      <!-- 私聊 -->
                      <div
                        class="agmsg-body-item-text"
                        v-if="n.type == 1 && !n.refContent"
                      >
                        <span style="white-space: pre-wrap">{{
                          ji.content
                        }}</span>
                      </div>
                      <div
                        class="agmsg-body-quote-item"
                        v-if="
                          (n.type == 1 || n.type == 4) &&
                          n.refContent &&
                          n.refName
                        "
                      >
                        <div class="agmsg-body-quote-content">
                          <span>{{ n.refName }}：</span>
                          <el-image
                            v-if="isImage(n.refContent)"
                            class="agmsg-body-item-char-img"
                            :src="n.refContent | image"
                            lazy
                          >
                          </el-image>
                          <span v-else>{{ n.refContent }}</span>
                        </div>
                        <div class="agmsg-body-quote-text">
                          {{ ji.content }}
                        </div>
                      </div>
                      <!-- 直播間 -->
                      <div
                        class="agmsg-body-item-text"
                        :class="{ 'agmsg-body-item-emoj': isEmoj(ji.content) }"
                        v-if="
                          n.contentType == 1 && n.type != 1 && !n.refContent
                        "
                      >
                        <span style="white-space: pre-wrap">{{
                          ji.content
                        }}</span>
                      </div>
                    </div>
                    <img
                      v-if="ji.type == 'image'"
                      class="json-content-image"
                      lazy-load
                      mode="widthFix"
                      :src="ji.content | url"
                    />
                    <img
                      v-if="ji.type == 'emoji'"
                      class="json-content-image"
                      lazy-load
                      mode="widthFix"
                      :src="ji.content"
                    />
                    <span
                      class="json-content-http"
                      v-if="ji.type == 'http'"
                      @click.stop="msgUrlTo(ji.content)"
                      >{{ ji.content }}</span
                    >
                  </div>
                </div>
                <!-- 图片 -->
                <!-- 广告 -->
                <div
                  class="agmsg-link"
                  :class="{ right: !msgLeft(n) }"
                  v-if="n.thumb"
                  @click.stop="urlTo(n)"
                >
                  <div
                    class="agmsg-link-content"
                    :class="{
                      menuActive6: menuActive == 6,
                      menuActive4: menuActive == 4
                    }"
                  >
                    <img :src="n.thumb | url" class="agmsg-link-img" />
                    <div class="agmsg-link-title">
                      {{ n.title }}
                    </div>
                    <div class="agmsg-link-body">
                      {{ n.content }}
                    </div>
                  </div>
                </div>
                <!-- 广告 -->
                <span
                  class="iconfont icon-del agmsg-body-item-del"
                  v-if="msgLeft(n) && delId == n.id"
                  @click.stop="delMsg(n, index)"
                ></span>
                <span
                  class="iconfont icon-share1"
                  :class="{ 'item-share-lf': msgLeft(n) }"
                  v-if="msgLeft(n) && n.contentType == 3"
                  @click.stop="shareImage(n)"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
      </div>

      <div
        v-show="showscrollinfo"
        class="agmsg-showscrollinfo"
        @click="onshowscrollinfo"
      >
        向下滚动查看更多消息
      </div>
    </div>
    <div
      class="agmsg-bottom"
      :class="{ advertisement: active == 'advertisement' }"
    >
      <div class="agmsg-bottom-title">
        <div
          class="agmsg-bottom-title-item"
          :class="{ on: active == 'ordinary' }"
          @click="handleClick($event, 'ordinary')"
        >
          <span class="agmsg-bottom-title-item-text">普通消息</span>
        </div>
        <div
          class="agmsg-bottom-title-item"
          :class="{ on: active == 'advertisement' }"
          @click="handleClick($event, 'advertisement')"
        >
          <span class="agmsg-bottom-title-item-text">广告</span>
        </div>
        <div
          class="agmsg-bottom-title-item"
          :class="{ on: active == 'vote', not: talking1.messageType != 1 }"
          @click="handleClick($event, 'vote')"
        >
          <span class="agmsg-bottom-title-item-text">投票</span>
        </div>
        <div class="emotion-box">
          <i
            class="iconfont icon-outlined_stricker emotion-icon"
            @click.stop="iconshow = !iconshow"
          ></i>
          <div class="emotion-popup" v-show="iconshow">
            <div class="ag-emoj-title">
              <span>表情字符</span>
              <i class="el-icon-close" @click="iconshow = false"></i>
            </div>
            <div class="emotion-list">
              <emotion
                class="emotion-item"
                v-for="(item, i) in vars.emojis"
                :key="i"
                @click.native="clickHandler(item.emoj)"
                :title="item.name"
                >{{ item.emoj }}</emotion
              >
            </div>
            <!-- <span v-for="(item,index) in vars.emojis" :key="index"  @click="clickHandler(item)">{{item}}</span> -->
          </div>
          <!-- <i class="el-icon-caret-bottom emotion-caret" v-show="iconshow"></i> -->
        </div>
      </div>
      <div class="main-center-bottom" v-if="active == 'ordinary'">
        <div class="atusers" v-show="atUserShow">
          <div
            class="atuserslist"
            v-for="(item, index) in talking1.atUser"
            :key="item.id"
            @click="atUser(item)"
          >
            <span class="atusersname">{{ getUserName(item, item.uid) }}</span>
          </div>
        </div>
        <div
          class="main-send-input"
          :class="{ isCopy: isCopy }"
          v-show="!showSale"
        >
          <div v-if="isCopy" class="copy-pic" ref="showCopyImg">
            <div class="copy-del">
              <span
                class="iconfont icon-baseline-close-px"
                @click="keyboardDelete"
                title="删除"
              ></span>
            </div>
            <img :src="copyUrl" />
          </div>
          <div class="main-send-conter" v-if="!isCopy">
            <el-input
              v-if="talking1.messageType != 1"
              @focus="
                iconshow = false;
                atUserShow = false;
              "
              :ref="'content-' + talking1.id"
              :class="{ 'copy-input': isCopy }"
              @keydown.native="onkeyup"
              @compositionstart.native="onCompositionStart"
              @compositionend.native="onCompositionEnd"
              type="textarea"
              v-model="form.content"
              rows="3"
              @paste.native.capture.prevent="pasting"
            >
            </el-input>
            <el-input
              v-else
              @focus="
                iconshow = false;
                atUserShow = false;
              "
              :ref="'content-' + talking1.id"
              @keydown.native="onkeyup"
              @compositionstart.native="onCompositionStart"
              @compositionend.native="onCompositionEnd"
              type="textarea"
              v-model="form.content"
              rows="3"
            >
            </el-input>
          </div>
        </div>
        <div class="main-center-button" v-show="!showSale">
          <el-input
            class="link-input"
            :value="shareLink + talking1.adminId"
            v-if="talking1.messageType == 1 && showRoom"
          ></el-input>
          <div class="mindiv">
            <div class="mindiv-content">
              <el-upload
                class="mindiv-upload"
                :action="uploadUrl"
                :limit="1"
                ref="upload"
                :on-success="imgUploader"
                :on-progress="proUploader"
                :before-upload="beforeAvatarUpload"
                :show-file-list="false"
              >
                <span class="iconfont icon-attach_file attach-file"></span>
              </el-upload>
              <!-- <div class="onekeylist" v-show="isonekey">
                                     <div class="atuserslist" @click="keysend(item)" v-for="(item, index) in onekeyList" :key="'onekey'+item + index">
                                         <span class="atusersname">{{item}}</span>
                                     </div>
                                </div> -->
              <div class="onekeydiv">
                <div class="onekey onekeyleft" @click="ongetKey">
                  <span class="iconfont icon-flash_on onekey-text flash"></span>
                  <span class="onekey-text">一键回复</span>
                </div>
                <div class="onekey onekeyright" @click="onsetKey">
                  <span class="onekey-text">保存</span>
                </div>
              </div>
            </div>
            <div class="mindiv-content mright">
              <el-checkbox
                v-model="form.push"
                v-if="talking1.messageType == 2"
                :true-label="1"
                :false-label="0"
                :disabled="talking1.messageType != 2"
                >离线推送</el-checkbox
              >
              <el-checkbox
                v-if="talking1.messageType == 1"
                v-model="form.type"
                :true-label="7"
                :false-label="4"
                :disabled="talking1.messageType != 1"
                >设为弹幕</el-checkbox
              >
              <el-button
                v-if="isCopy"
                class="send-button"
                type="success"
                :loading="loading"
                @click="sendCopy"
                >发送
              </el-button>
              <el-button
                v-else
                class="send-button"
                type="success"
                :loading="loading"
                @click="send"
                >发送
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告 -->
      <div v-if="active == 'advertisement'" class="agmsg-bottom-advertisement">
        <div class="msg-edit-link">
          <div class="msg-edit-link-left">
            <ag-uploader v-model="form.thumb" :allowImgType="allowImgType">
              <div
                style="
                  width: 88px;
                  height: 88px;
                  border: 1px dashed #d9d9d9;
                  line-height: 88px;
                "
                v-if="!form.thumb"
              >
                添加图片
              </div>
              <img
                style="
                  width: 88px;
                  height: 88px;
                  border: 1px dashed #d9d9d9;
                  line-height: 88px;
                "
                class="sale-pic-img"
                v-else
                :src="form.thumb | url"
              />
            </ag-uploader>
          </div>
          <div class="msg-edit-link-right">
            <div>
              <el-input
                class="link-left-input"
                show-word-limit
                maxlength="10"
                clearable
                v-model.trim="form.title"
                placeholder="请输入标题(显示10个字)">
              </el-input>
              <!-- <input
                class="link-left-input"
                maxlength="10"
                v-model.trim="form.title"
                placeholder="请输入标题(显示10个字)"
              /> -->
            </div>
            <div>
              <el-input
                class="link-left-content-text"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 3 }"
                maxlength="44"
                show-word-limit
                v-model.trim="form.content"
                placeholder="请输入内容(最多显示44个字)"
                ></el-input>
              <!-- <textarea
                class="link-left-content-text"
                maxlength="48"
                v-model.trim="form.content"
                placeholder="请输入内容(最多显示44个字)"
              ></textarea> -->
            </div>
            <div>
              <el-input
                class="link-left-url"
                v-model.trim="form.url"
                clearable
                placeholder="请输入链接">
              </el-input>
              <!-- <input
                class="link-left-url"
                v-model.trim="form.url"
                placeholder="请输入链接"
              /> -->
            </div>
          </div>
        </div>
        <div
          class="mindiv advertisement"
          :class="{ menuActive: menuActive == 6 }"
        >
          <div class="mindiv-content mright">
            <el-checkbox
              v-model="form.push"
              v-if="talking1.messageType == 2"
              :true-label="1"
              :false-label="0"
              :disabled="talking1.messageType != 2"
              >离线推送</el-checkbox
            >
            <el-checkbox
              v-model="form.type"
              :true-label="7"
              :false-label="4"
              :disabled="talking1.messageType != 1"
              >设为弹幕</el-checkbox
            >
            <el-button
              v-if="isCopy"
              class="send-button"
              type="success"
              @click="sendCopy"
              >发送
            </el-button>
            <el-button v-else class="send-button" type="success" @click="send"
              >发送
            </el-button>
          </div>
        </div>
      </div>
      <!-- 广告 -->
      <!-- 投票 -->
      <div
        class="agmsg-bottom-vote"
        v-if="active == 'vote'"
        v-loading="pageVoteing"
      >
        <div class="vote-not" v-if="!setVote">
          <span class="vote-not-text">尚无投票活动</span>
          <el-button @click="onVote" class="vote-button" type="success"
            >创建投票
          </el-button>
        </div>
        <div class="vote-item" v-else>
          <div class="vote-item-content">
            <div class="vote-title">
              <span class="vote-title-text">{{ vote.title }}</span>
              <span class="vote-title-date" v-if="vote.beginTime">{{
                vote.beginTime + '~' + vote.endTime
              }}</span>
            </div>
            <div class="vote-progress">
              <span class="vote-p-text">{{ vote.optionA }}</span>
              <div class="vote-p" :style="{ width: countA }"></div>
              <span class="vote-progress-text">{{ vote.countA }}</span>
            </div>
            <div class="vote-progress pb">
              <span class="vote-p-text">{{ vote.optionB }}</span>
              <div class="vote-p" :style="{ width: countB }"></div>
              <span class="vote-progress-text">{{ vote.countB }}</span>
            </div>
          </div>
          <div class="vote-item-button">
            <el-button class="vote-button" type="success" @click="endVote"
              >提前结束
            </el-button>
          </div>
        </div>
      </div>
      <!-- 投票 -->
    </div>
    <el-dialog
      title="创建投票"
      :visible.sync="isVote"
      v-loading="primaryVoteing"
    >
      <el-form :model="vote">
        <el-form-item label="投票主题" label-width="80px" required>
          <el-input v-model="vote.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选项A" label-width="80px" required>
          <el-input v-model="vote.optionA" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选项B" label-width="80px" required>
          <el-input v-model="vote.optionB" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="投票时间" label-width="90px" required>
          <el-time-picker
            is-range
            v-model="vote.date"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="send-button" @click="clearVote">取 消</el-button>
        <el-button class="send-button" type="primary" @click="primaryVote"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <div
      v-if="dialogEnlargeImageUrl"
      class="agmsg-enlarg-popup"
      @click="dialogEnlargeImageUrl = false"
    >
      <a :href="dialogEnlargeImageUrl | image" target="_blank">
        <img @click.stop="" :src="dialogEnlargeImageUrl | image" alt="" />
      </a>
    </div>
    <!-- 一键回复内容 -->
    <el-dialog
      title="一键回复内容"
      :visible.sync="isonekey"
      width="416px"
      @close="isonekey = false"
    >
      <div
        class="setup-group-content"
        v-if="onekeyList && onekeyList.length > 0"
      >
        <div class="user-list-content">
          <div
            class="user-list-name"
            v-for="(item, index) in onekeyList"
            :key="'onekey' + item + index"
          >
            <div class="user-list-box">
              <div
                class="box-rg"
                style="cursor: pointer"
                @click="keysend(item)"
              >
                <div class="rg-name" style="height: unset">{{ item }}</div>
              </div>
              <div
                class="user-rg"
                style="cursor: pointer"
                @click="ondelkey(index)"
              >
                <!-- <div style="color: red;">删除</div> -->
                <i class="iconfont icon-close"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isonekey = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 个人 -->
    <el-dialog :visible.sync="isPerInfo" width="414px">
      <div class="perinfo" v-loading="perInfoData">
        <div class="perinfo-img">
          <img v-if="perInfo.avatar" :src="perInfo.avatar | url" />
          <span v-else>{{
            perInfo.userNicename && perInfo.userNicename.charAt(0)
          }}</span>
        </div>
        <div class="perinfo-name">
          <span class="perinfo-name-text">{{ perInfo.userNicename }}</span>
          <div
            class="perinfo-state"
            :class="{ 'not-line': !perInfo.online }"
          ></div>
        </div>
        <div class="perinfo-remark" v-if="isEditRemark != perInfo.id">
          <div class="perinfo-remark-user">
            {{ perInfo.remark || getUserName(perInfo, perInfo.id) }}
          </div>
          <div class="perinfo-remark-icon" @click.stop="editRemark(perInfo)">
            <i class="iconfont icon-pen"></i>
          </div>
        </div>
        <div
          class="input-with-remark"
          v-if="isEditRemark == perInfo.id"
          @click.stop="onClick"
        >
          <el-input
            placeholder="备注昵称"
            v-model="remark"
            @blur.stop="handleRemarksBlur($event, perInfo)"
            @focus.stop="handleRemarksFocus($event, perInfo)"
            @keyup.native.space="keyboardSpace"
            @keyup.native.enter="confirmRemark($event, perInfo)"
            :ref="'remarks-' + perInfo.id"
          >
          </el-input>
          <el-button
            slot="append"
            icon="el-icon-check"
            @click="confirmRemark($event, perInfo)"
          ></el-button>
        </div>
        <div class="perinfo-id">{{ perInfo.id }}</div>
        <div class="user-btn-box">
          <el-button
            plain
            size="small"
            :disabled="talking1.messageType == 3"
            @click="psiliao(perInfo)"
            >私聊</el-button
          >
          <el-button plain size="small" @click="addgroup(perInfo)"
            >加群</el-button
          >
          <el-button
            plain
            size="small"
            v-if="
              perInfo &&
              perInfo.id &&
              (talking1.messageType == 1 ||
                talking1.messageType == 3 ||
                talking1.messageType == 4)
            "
            @click="mute($event, perInfo)"
            >{{
              getUserMute(talking1, perInfo.id).mute == 1 ? '解禁' : '禁言'
            }}</el-button
          >
          <el-button
            plain
            size="small"
            v-if="perInfo && perInfo.id && talking1.messageType == 2"
            @click="mute($event, perInfo)"
            >{{
              getUserMute(talking1, perInfo.id).muted == 1 ? '解禁' : '禁言'
            }}</el-button
          >
          <el-button plain size="small" @click="userCancel(perInfo)">{{
            getUserCancel(perInfo.id) == 1 ? '解冻' : '冻结'
          }}</el-button>
        </div>
        <div class="perinfo-per">
          <div class="per-title">个人资讯</div>
          <div class="info-it">
            <span>注册时间：</span>
            <span>{{ perInfo.createTime }}</span>
          </div>
          <div class="info-it">
            <span>终端类型：</span>
            <span>{{ perInfo.source }}</span>
          </div>
          <div class="info-it">
            <span>观赛次数：</span>
            <span v-for="m in viewMatch" :key="m.count">{{ m.count }}</span>
          </div>
          <div class="info-it">
            <span>最后观看：</span>
            <span>-</span>
          </div>
          <div class="info-it">
            <span>设置纪录：</span>
            <span>-</span>
          </div>
        </div>
        <div class="perinfo-label">
          <div class="per-title">标签</div>
          <div class="perinfo-user-tag-box">
            <div
              class="perinfo-tag-list"
              v-if="perInfo.userTab && perInfo.userTab.length > 0"
            >
              <div
                class="perinfo-tag-item"
                v-for="t in perInfo.userTab"
                :key="t"
              >
                <span>{{ t }}</span>
                <i class="iconfont icon-close" @click="delTag(t, perInfo)"></i>
              </div>
            </div>
            <div class="perinfo-tag-btn">
              <div
                class="perinfo-tag-add"
                v-if="isAddTag != perInfo.id"
                @click="addTag(perInfo)"
              >
                <i class="iconfont icon-add"></i>
                <span>新增</span>
              </div>
              <div class="perinfo-tag-input" v-if="isAddTag == perInfo.id">
                <el-input
                  size="mini"
                  placeholder="备注标签"
                  v-model="tagVal"
                  @blur="handleTagBlur($event)"
                  @keyup.native.enter="confirmTag(perInfo)"
                  :ref="'tag-' + perInfo.id"
                >
                </el-input>
                <el-button
                  icon="el-icon-check"
                  @click.stop="confirmTag(perInfo)"
                ></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vars from '@/config/vars';
import util from '@/config/util';
import api from '@/config/api';
import sport from '@/config/sport_manage.js';
import Emotion from '@/components/emojCharacter';
import { uploadUrl, downloadUrl } from '@/config/env';
export default {
  components: {
    Emotion
  },
  props: {
    talking: {
      type: Object,
      default: function () {
        return {};
      }
    },
    messageList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    istalking: {
      type: Boolean,
      default: false
    },
    menuActive: {
      type: Number,
      default: 1
    },
    tab: {
      type: Number,
      default: 1
    },
    indexMsg: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      menuTop: 0,
      menuLeft: 0,
      uploadUrl,
      downloadUrl,
      vars,
      isVote: false,
      showRoom: false,
      active: 'ordinary',
      form: {
        at: [],
        userType: 2,
        size: 10,
        page: 1,
        content: '',
        url: '',
        title: '',
        roomId: 0,
        groupId: 0,
        thumb: '',
        contentType: 1,
        type: 4,
        copyContent: '',
        push: 0
      },
      isEditRemark: '',
      messageList2: [],
      vote: {
        title: '',
        optionA: '',
        optionB: '',
        status: 0,
        countA: 0,
        countB: 0
      },
      setVote: false,
      talking1: {},
      countA: 0,
      countB: 0,
      showSale: false,
      isCopy: false,
      copyUrl: '',
      copyFile: '',
      copyLoading: false,
      copySize: 0,
      iconshow: false,
      atUserShow: false,
      loading: false,
      isEnter: false,
      delId: '',
      dialogEnlargeImageUrl: '',
      isrigh: false,
      showscrollinfo: false,
      divider: {},
      isPerInfo: false,
      perInfo: {},
      isEditRemark: '',
      viewMatch: {},
      isAddTag: 0,
      tagVal: '',
      remark: '',
      canceling: false,
      muteling: false,
      prevMsging: false,
      prevMsgTime: 0,
      perInfoData: false,
      pageVoteing: false,
      primaryVoteing: false,
      loadImg: false,
      imgtid: 0,
      isonekey: false,
      onekeyList: [],
      allowImgType: [...util.allowImgTypeConfig, 'gif'],
      isComposing: false,
    };
  },
  watch: {
    messageList(n) {
      this.messageList2 = n;
      // this.showscrollinfo = false
      this.scroll();
    },
    talking(n) {
      this.active = 'ordinary';
      this.vote = {
        title: '',
        optionA: '',
        optionB: '',
        status: 0,
        countA: 0,
        countB: 0
      };
      this.showscrollinfo = false;
      this.divider = {};
      this.talking1 = n;
    }
  },
  mounted() {
    this.messageList2 = this.messageList;
    this.talking1 = this.talking;
    // this.isonekey = localStorage.getItem('onekey')  ? true : false
    this.scroll();
  },
  computed: {
    showType() {
      return this.talking1.messageType;
    },
    messageList3() {
      let list = [];
      let map = {};
      for (let i in this.messageList2) {
        let r = this.messageList2[i];
        let m = map[r.id];
        if (m && m.id) {
          continue;
        }
        map[r.id] = r;
        if (r.messageType == 1) {
          let u = this.talking1.blockMap && this.talking1.blockMap[r.senderId];
          if (u && u.id) {
            r.muteId = u.id;
            r.mute = u.mute;
          } else {
            r.mute = 0;
          }
        }
        let user = this.$store.state.userRemarkMap[r.senderId];
        if (user && user.id) {
          r.user = user;
          r.user.cancel =
            user.joinMap && user.joinMap.u && user.joinMap.u.cancel;
        } else {
          let name = sport.getName(r);
          let avatar = r.joinMap && r.joinMap.u && r.joinMap.u.avatar;
          let cancel = 0;
          r.user = {
            name,
            avatar,
            cancel
          };
        }
        list.push(r);
      }
      return list;
    },
    messageList4() {
      let list = this.messageList3.sort((a, b) => {
        if (a.addTime == b.addTime) {
          return a.id < b.id ? -1 : 1;
        }
        return a.addTime <= b.addTime ? -1 : 1;
      });
      return list;
    }
  },
  created() {
    util.on('msg', this.onmsg);
  },
  destroyed() {
    util.off('msg', this.onmsg);
  },
  methods: {
    isImage(content) {
      let reg = /^202\d\/\d{4}\//;
      return reg.test(content);
    },
    keysend(item) {
      this.form.content += item;
      this.isonekey = false;
    },
    ondelkey(index) {
      this.onekeyList.splice(index, 1);
      localStorage.setItem('onekey', this.onekeyList.toString());
    },
    ongetKey() {
      let onekey = localStorage.getItem('onekey');
      let s = [];
      if (onekey) {
        s = onekey.split(',');
      }
      if (s.length > 0) {
        this.isonekey = !this.isonekey;
      }
      this.onekeyList = s;
    },
    onsetKey() {
      let content = this.form.content;
      if (!content || content.length <= 0) {
        util.message('请输入回复内容');
        return;
      }
      let onekey = localStorage.getItem('onekey');
      let s = [];
      if (onekey) {
        s = onekey.split(',');
      }
      s.push(content);

      localStorage.setItem('onekey', s.toString());
      util.message('保存一键回复成功');
      //
    },
    getReadMsg(id, status) {
      if (status == 1) {
        return '已读';
      }
      let r = this.$store.state.readMap[id];
      if (r) {
        return '已读';
      }

      return '未读';
    },
    onInfoDetail() {
      let n = Object.assign({}, this.talking1);
      this.$emit('onInfoDetail', n);
    },
    atUser(item) {
      this.atUserShow = false;

      let ei = this.form.content.lastIndexOf('@');
      let c = this.form.content.substr(0, ei + 1);
      this.form.content = c + item.joinMap.u.userNicename + ' ';
      this.form.at.push(item.id);
    },
    prevMsg() {
      let time = new Date().getTime();
      if (this.prevMsgTime > time - 1000) {
        return;
      }
      this.prevMsgTime = time;
      if (this.prevMsging) {
        util.message('数据正在加载，请稍后。。。');
        return;
      }
      this.prevMsging = true;
      if (this.talking1.messageType == 1) {
        let f = { roomId: this.talking1.id, size: 20 };
        if (this.messageList2.length > 0) {
          f.toId = this.messageList2[0].id;
        }
        api
          .pageRoomMsg(f)
          .then((resp) => {
            this.prevMsging = false;
            if (resp.data.total == 0) {
              util.message('没有更多消息');
              return;
            }
            let id;
            if (resp.data.list && resp.data.list.length > 0) {
              id = resp.data.list[0].id;
            }
            // let id
            resp.data.list.forEach((r) => {
              this.addMessage(r, true);
            });
            this.goscroll(id, true);
          })
          .catch((res) => {
            this.prevMsging = false;
            // uni.stopPullDownRefresh()
            util.message(res.message);
          });
      }
      if (this.talking1.messageType == 3 || this.talking1.messageType == 4) {
        let f = { friendId: this.talking1.friendId, size: 20 };
        if (this.messageList2.length > 0) {
          f.toId = this.messageList2[0].id;
        }
        api
          .pageMsg(f)
          .then((resp) => {
            this.prevMsging = false;
            // this.refreshing = false
            // uni.stopPullDownRefresh()
            if (resp.data.total == 0) {
              // this.autoScroll = true
              util.message('没有更多消息');
              return;
            }
            let id;
            if (resp.data.list && resp.data.list.length > 0) {
              id = resp.data.list[0].id;
            }
            // let id
            resp.data.list.forEach((r) => {
              this.addMessage(r, true);
            });
            this.goscroll(id, true);
          })
          .catch((res) => {
            this.prevMsging = false;
            // uni.stopPullDownRefresh()
            util.message(res.message);
          });
      }

      if (this.talking1.messageType == 2) {
        let f = { groupId: this.talking1.groupId, size: 20 };
        if (this.messageList2.length > 0) {
          f.toId = this.messageList2[0].id;
        }
        api
          .pageGroupMsg(f)
          .then((resp) => {
            this.prevMsging = false;
            // uni.stopPullDownRefresh()
            if (resp.data.total == 0) {
              // this.autoScroll = true
              util.message('没有更多消息');
              return;
            }
            let id;
            if (resp.data.list && resp.data.list.length > 0) {
              id = resp.data.list[0].id;
            }
            // let id
            resp.data.list.forEach((r) => {
              this.addMessage(r, true);
            });
            this.goscroll(id, true);
          })
          .catch((res) => {
            this.prevMsging = false;
            // uni.stopPullDownRefresh()/
            util.message(res.message);
          });
      }
    },
    goscroll(id, notbehavior) {
      let pageId = document.getElementById('v' + this.indexMsg + '-' + id);
      let behavior = 'smooth';
      if (notbehavior) {
        behavior = 'auto';
      }
      if (pageId) {
        pageId.scrollIntoView({ behavior, block: 'start', inline: 'start' });
        return;
      }
      this.$nextTick(() => {
        pageId = document.getElementById('v' + this.indexMsg + '-' + id);
        if (pageId) {
          pageId.scrollIntoView({ behavior, block: 'start', inline: 'start' });
          return;
        }
      });
    },
    onmouseout() {
      this.isrigh = '';
    },

    setUpFocus() {
      this.$nextTick(() => {
        if (this.$refs['content-' + this.talking1.id]) {
          this.$refs['content-' + this.talking1.id].focus();
        }
      });
    },
    getUserName(n, id) {
      let user = this.$store.state.userRemarkMap[id];
      let name =
        (n.joinMap && n.joinMap.u && n.joinMap.u.userNicename) ||
        n.userNicename;
      if (user && user.id) {
        name = user.remark;
      } else if (
        this.talking1.messageType == 3 ||
        this.talking1.messageType == 4
      ) {
        let ur = this.talking1.joinMap && this.talking1.joinMap.ur;
        if (ur && ur.id) {
          name = ur.remark;
        }
        if (!name) {
          name = this.talking1.name;
        }
      }
      return name;
    },
    getJsonContent(n) {
      if (!n) {
        return '';
      }
      try {
        let content = JSON.parse(n);
        let nicename = '';
        for (let i in content) {
          let c = content[i];
          if (nicename) {
            nicename += '、' + c.nicename;
          } else {
            nicename = c.nicename;
          }
        }
        return nicename;
      } catch (error) {
        return '';
      }
    },
    getUserOnLine(id) {
      let user = this.$store.state.userMap[id];
      if (!user || !user.id) {
        return false;
      }
      if (user.onlineApp || user.onlineWeb) {
        return true;
      }
      return false;
    },
    getUserCancel(id) {
      let user = this.$store.state.userMap[id];
      if (!user || !user.id) {
        return 0;
      }
      return user.cancel;
    },
    getUserMute(t, id) {
      if (t.messageType == 2) {
        let u = this.$store.getters.getGroupUserById(t.groupId, id);
        if (!u || !u.id) {
          return false;
        }
        return u;
      }
      if (t.messageType == 3) {
        let u = this.talking1.blockMap && this.talking1.blockMap[id];
        if (!u || !u.id) {
          return false;
        }
        return u;
      }
      let rid = t.id;
      let u = this.$store.getters.getRoomUserById(rid, id);
      if (!u || !u.id) {
        return false;
      }
      return u;
    },
    psiliao(item) {
      let r = {
        senderId: item.id
      };
      let roomName = this.talking1.name || this.talking1.groupName;
      this.$emit('siliao', r, roomName);
      this.isPerInfo = false;
    },
    handleTagBlur(e) {
      e.stopPropagation();
      this.$nextTick(() => {
        setTimeout(() => {
          this.isAddTag = 0;
        }, 1000);
      });
    },
    addTag(item) {
      this.isAddTag = item.id;
      this.$nextTick(() => {
        if (this.$refs['tag-' + item.id]) {
          this.$refs['tag-' + item.id].focus();
        }
      });
    },
    confirmTag(item) {
      let id = item.id;
      let tab = this.tagVal;
      if (tab) {
        api
          .editUserTab({
            id,
            tab
          })
          .then((resp) => {
            util.success('添加成功');
            item.userTab.push(tab);
            this.isAddTag = false;
            this.tagVal = '';
          })
          .catch((res) => {
            util.error(res.message);
          });
      }
    },
    delTag(tab, tag) {
      let id = tag.id;
      let l = tag.userTab;

      api
        .removeUserTab({
          id,
          tab
        })
        .then((resp) => {
          util.success('删除成功');
          l.splice(l.indexOf(tab), 1);
          this.$set(tag, 'tabsList', l);
        })
        .catch((res) => {
          util.error(res.message);
        });
    },
    editRemark(tag) {
      this.isEditRemark = tag.id;
      this.remark = this.getUserName(tag, tag.id);
      this.$nextTick(() => {
        this.$refs['remarks-' + tag.id].focus();
      });
    },
    keyboardSpace(e) {
      e.stopPropagation();
    },
    confirmRemark(e, tag) {
      e.stopPropagation();
      if (!this.remark) {
        util.error('请输入备注名');
        return;
      }
      let remark = this.remark;
      let user = this.$store.state.userRemarkMap[tag.id];
      let id = '';
      if (user && user.id) {
        id = user.id;
      }
      let uid = tag.id;
      api
        .editUserRemark({
          id,
          uid,
          remark
        })
        .then((resp) => {
          this.isEditRemark = false;
          tag.remark = resp.data.remark;
          if (tag && tag.joinMap && tag.joinMap.ur) {
            tag.joinMap.ur.id = resp.data.id;
            tag.joinMap.ur.remark = resp.data.remark;
          }
          let u = this.$store.state.userRemarkMap[uid];
          if (u && u.id) {
            u.id = resp.data.id;
            u.remark = resp.data.remark;
            let us = Object.assign({}, u);
            this.$store.commit('setUserRemark', { r: u, id: uid });
          } else {
            let ur = resp.data;
            this.$store.commit('setUserRemark', { r: ur, id: uid });
          }
          let f = this.$store.state.message.map.friend[uid];
          if (f && f.joinMap) {
            f.joinMap.ur = {
              id: resp.data.id,
              remark: resp.data.remark
            };
            f.name =
              (f.joinMap && f.joinMap.ur && f.joinMap.ur.remark) ||
              (f.joinMap && f.joinMap.u && f.joinMap.u.userNicename);
            f.joinMap.ur.remark = resp.data.remark;
            let obf = Object.assign({}, f);
            this.$store.commit('messageUpdateFriend', obf);
            // this.$set(this.$store.state.message.map,'friend',obf)
          }
          this.remark = '';
          util.message(resp.message);
        })
        .catch((err) => {
          util.error(err.message);
        });
    },
    handleRemarksBlur(e, tag) {
      e.stopPropagation();
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isEditRemark == tag.id) {
            this.isEditRemark = 0;
            this.isdisabled = 0;
          }
        }, 500);
      });
    },
    handleRemarksFocus(e, tag) {
      e.stopPropagation();
      this.isdisabled = tag.id;
    },
    onClick(e) {
      e.stopPropagation();
    },
    onPerInfo(n) {
      this.isPerInfo = true;
      this.perInfoData = true;
      let id = n.senderId;
      api
        .countViewMatch({
          id
        })
        .then((resp) => {
          this.perInfoData = false;
          this.perInfo = resp.data.info;
          this.viewMatch = resp.data.view;
          // tag.joinMap && tag.joinMap.ur &&tag.joinMap.ur.id
          this.perInfo.remark =
            (this.perInfo.joinMap &&
              this.perInfo.joinMap.ur &&
              this.perInfo.joinMap.ur.remark) ||
            '呢称';
          if (
            !resp.data.info ||
            !resp.data.info.userType ||
            resp.data.info.userType == 3
          ) {
            this.perInfo.userNicename = '游客#' + id;
          }
          let tabsList = resp.data.userTab;
          let userTab = [];
          for (let i in tabsList) {
            let r = tabsList[i];
            userTab.push(r.tab);
          }
          // if(this.perInfo.tabs){
          //     tabsList = this.perInfo.tabs.split(',')
          // }
          this.$set(this.perInfo, 'userTab', userTab);
          // this.$set(tag, 'viewMatch', resp.data)
        })
        .catch((res) => {
          util.error(res.message);
        });
    },
    isIndexOfImg(t) {
      if (
        t.indexOf('image/png') >= 0 ||
        t.indexOf('image/jpg') >= 0 ||
        t.indexOf('image/jpeg') >= 0 ||
        t.indexOf('image/bmp') >= 0 ||
        t.indexOf('image/gif') >= 0
      ) {
        return true;
      }
      return false;
    },
    clearMsgUnread(r) {
      let message = this.$store.state.message;
      let target = undefined;

      if (r.messageType == 1) {
        target = message.map.room[r.roomId || r.id];
        this.$store.commit('messageCurrent', target);
        // target.unread = 0
      }
      if (r.messageType == 2) {
        target = message.map.group[r.groupId || r.senderId];
        this.$store.commit('messageCurrent', target);
        // target.unread = 0
      }
      if (r.messageType == 3) {
        target = message.map.friend[r.friendId || r.senderId];
        this.$store.commit('messageCurrent', target);
        // target.unread = 0
      }
    },
    onshowscrollinfo() {
      // this.talking1.unread=0
      this.clearMsgUnread(this.talking1);
      if (this.divider && this.divider.time) {
        this.$nextTick(function () {
          let el = this.$refs.msglist;
          if (!el) {
            return;
          }
          el.scrollTop = this.divider.time;
          this.showscrollinfo = false;
          this.divider.time = 0;
        });
        return;
      }
      this.divider = {};
      this.showscrollinfo = false;
      this.scroll();
    },
    showMoreMessage() {
      this.$nextTick(() => {
        let msglist = this.$refs.msglist;
        if (msglist) {
          let d = msglist.scrollHeight - msglist.scrollTop;
          let offsetHeight = msglist.offsetHeight + 150;
          this.showscrollinfo = d > offsetHeight;
          this.talking1.showscrollinfo = this.showscrollinfo;
        }
      });
    },
    loadFootMore() {
      if (this.divider.id) {
        this.onshowscrollinfo();
      }
    },
    ondraw() {
      if (!this.talking1.ismag) {
        util.error('请稍等....');
        return;
      }
      let t = Object.assign({}, this.talking1);
      let m = Object.assign({}, this.messageList2);
      let item = {
        time: new Date().getTime(),
        id: t.id,
        talking: t,
        messageList: this.messageList2,
        messageType: t.messageType
      };
      if (this.talking1.messageType == 3 || this.talking1.messageType == 4) {
        item.id = t.friendId;
      }
      if (this.talking1.messageType == 2) {
        item.id = t.groupId;
      }
      this.$emit('ondraw', item);
    },
    addgroup(n) {
      let i = {
        friendId: n.senderId || n.id
      };
      this.$emit('addgroup', i);
    },
    siliao(e, n) {
      if (this.talking1.messageType == 3) {
        e.preventDefault();
        return;
      }
      let roomName = this.talking1.name || this.talking1.groupName;
      this.$emit('siliao', n, roomName);
    },
    mute(e, item) {
      if (this.talking1.messageType == 3) {
        e.preventDefault();
        return;
      }
      if (this.muteling) {
        return;
      }
      this.muteling = true;

      if (this.talking1.messageType == 1) {
        this.editRoomUserMute(item);
        return;
      }
      if (this.talking1.messageType == 2) {
        this.editGroupUserMute(item);
        return;
      }
    },
    editRoomUserMute(item) {
      if (!item) {
        item = Object.assign({}, this.perInfo);
      }
      let roomId = this.talking1.id;
      let id = item.senderId || this.perInfo.id;
      let user = this.getUserMute(this.talking1, id);
      let i = {
        id: user.id,
        mute: user.mute == 1 ? 0 : 1
      };
      // if(!i.id){
      //     let umap = this.talking1.userMap && this.talking1.userMap[id]
      //     if(umap && umap.id){
      //         i.id = umap.id
      //     }
      // }
      let name = item.userNicename || item.joinMap.u.userNicename;
      let mute = user.mute == 1 ? '解禁' : '禁言';
      util.confirm(`是否${mute}"${name}"？`, () => {
        api
          .editMute(i)
          .then((resp) => {
            this.muteling = false;
            let r = resp.data;
            // let u = this.talking1.blockMap[r.uid]
            item.mute = r.mute;

            let userMap = this.$store.getters.getRoomUserByRid(roomId);
            let user = this.$store.getters.getRoomUserById(roomId, id);

            if (!user) {
              user = item;
              this.$set(userMap, r.uid, user);
              return;
            }
            user.mute = r.mute;
            this.$set(userMap, r.uid, user);
            // if(!u  || !u.id){
            //     this.talking1.blockMap[r.uid] = resp.data
            // }else {
            //     this.$delete(this.talking1.blockMap,r.uid)
            //     this.$set(this.talking1,'blockMap',this.talking1.blockMap)
            // }
            // let map = this.$store.state.drawMap[this.talking1.id]
            // if(map && map.id){
            //    this.$set(map,'talking',this.talking1)
            // }
            // let ur = this.$store.state.userMap[r.uid]
            // if(ur && ur.id){
            //     // console.log("qwe",r.mute)
            //     ur.mute = r.mute
            //     let um = Object.assign({},ur)
            //     this.$store.commit("setUser",{r:um,id:ur.uid})
            // }
            util.message(`${mute}成功`);
          })
          .catch((res) => {
            this.muteling = false;
            util.error(res.message);
          });
      });
      this.muteling = false;
    },
    editGroupUserMute(item) {
      if (!item) {
        item = Object.assign({}, this.perInfo);
      }
      let id = item.senderId || this.perInfo.id;
      let groupId = this.talking1.groupId;
      let user = this.getUserMute(this.talking1, id);
      let i = {
        id: user.id,
        muted: user.muted == 1 ? 0 : 1
      };
      let name = item.userNicename || item.joinMap.u.userNicename;
      let muted = user.muted == 1 ? '解禁' : '禁言';
      util.confirm(`是否${muted}"${name}"？`, () => {
        api
          .editGroupUserMuted(i)
          .then((resp) => {
            this.muteling = false;
            // let groupMap = this.$store.getters.getGroupUserByRid(groupId)
            let u = this.$store.getters.getGroupUserById(groupId, id);
            if (u && u.id) {
              u.muted = i.muted;
              this.$store.commit('updateGroupMuted', { r: u, groupId });
            }
            // if (!user) {
            // 	user = item
            // 	this.$set(map,id,user)
            // 	return
            // }
            // user.muted = i.muted
            // this.$set(user,'muted',i.muted)
            // this.$set(groupMap,id,user)
            util.message(`${muted}成功`);
          })
          .catch((res) => {
            this.muteling = false;
            util.error(res.message);
          });
      });
      this.muteling = false;
    },
    userCancel(item) {
      if (this.canceling) {
        return;
      }
      this.canceling = true;
      let id = item.senderId || item.id;
      let cancel = this.getUserCancel(id) == 1 ? 0 : 1;
      let msg = this.getUserCancel(id) == 1 ? '解冻' : '冻结';
      let name = item.userNicename || item.joinMap.u.userNicename;
      util.confirm(`是否${msg}账号"${name}"？`, () => {
        api
          .editUsersCancel({
            id: id,
            cancel
          })
          .then((resp) => {
            this.canceling = false;
            let r = resp.data;
            let u = this.$store.state.userMap[r.id];
            if (item.user) {
              item.user.cancel = cancel;
            } else {
              item.cancel = cancel;
            }
            if (!u || !u.id) {
              r.joinMap.u.cancel = r.cancel;
              this.$store.commit('userMap', { r, id: r.id });
            } else {
              u.cancel = r.cancel;
              u.joinMap.u.cancel = r.cancel;
              this.$store.commit('setUser', { r: u, id: u.uid });
            }
            util.message(resp.message);
          })
          .catch((res) => {
            this.canceling = false;
            util.error(res.message);
          });
      });
      this.canceling = false;
    },
    onRight(e, n) {
      this.menuTop = e.clientY;
      this.menuLeft = e.clientX;
      e.preventDefault();
      if (this.talking1.messageType == 1) {
        let rid = this.talking1.id;
        let uid = n.senderId;
        let guestForm = {
          roomId: rid,
          uid
        };
        api
          .listRoomUser(guestForm)
          .then((resp) => {
            if (!resp.data || !resp.data.length) {
              return;
            }
            let list = resp.data;
            let map = {};
            for (let i in list) {
              let r = list[i];
              r.name = r.joinMap && r.joinMap.u && r.joinMap.u.userNicename;
              r.remark =
                (r.joinMap && r.joinMap.ur && r.joinMap.ur.remark) || '昵称';
              if (
                (r.joinMap && r.joinMap.u && r.joinMap.u.userType == 3) ||
                (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename)
              ) {
                r.joinMap.u.userNicename = '游客#' + r.uid;
                r.joinMap.u.userType = 3;
                r.name = r.joinMap.u.userNicename;
              }
              r.cancel = r.joinMap && r.joinMap.u && r.joinMap.u.cancel;
              r.index = i;
              let m = map[r.uid];
              if (!m || !m.id) {
                map[r.uid] = r;
              }
              let id = r.uid;
              this.$store.commit('userMap', { r, id });
            }
            this.isrigh = n.id;
            this.$store.commit('roomUserList', { roomId: rid, map: map });
          })
          .catch((res) => {
            util.message('获取用户信息失败');
          });
      } else if (this.talking1.messageType == 2) {
        let id = n.senderId;
        let userGroup = this.getUserMute(this.talking1, id);
        let groupId = this.talking1.groupId;
        if (!userGroup || !userGroup.id) {
          api
            .pageGroupUser({
              groupId: groupId,
              uid: id
            })
            .then((resp) => {
              if (!resp.data || !resp.data.list || !resp.data.list.length) {
                return;
              }
              let list = resp.data.list;
              let map = {};
              for (let i in list) {
                let r = list[i];
                r.name = r.joinMap && r.joinMap.u && r.joinMap.u.userNicename;
                r.remark = r.joinMap && r.joinMap.ur && r.joinMap.ur.remark;
                r.online = r.joinMap && r.joinMap.u && r.joinMap.u.online;
                if (
                  (r.joinMap && r.joinMap.u && r.joinMap.u.userType == 3) ||
                  (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename)
                ) {
                  r.joinMap.u.userNicename = '游客#' + r.uid;
                  r.joinMap.u.userType = 3;
                  r.name = r.joinMap.u.userNicename;
                }
                let m = map[r.uid];
                if (!m || !m.id) {
                  map[r.uid] = r;
                }
              }
              this.$store.commit('groupUserMutedList', { groupId, map: map });
              this.isrigh = n.id;
            });
          return;
        }
        this.isrigh = n.id;
      } else {
        this.isrigh = n.id;
      }
    },
    ondialogEnlargeImage(url) {
      this.dialogEnlargeImageUrl = url;
    },
    loadingImg(e) {
      this.loadImg = false;
      this.scroll();
    },
    msgUrlTo(href) {
      window.open(href);
    },
    urlTo(n) {
      window.open(n.url);
    },
    delMsg(n, index) {
      if (n.messageType == 1) {
        if (n.type == vars.TYPE_ENTER) {
          this.messageList2.splice(index, 1);
          util.message('操作成功');
          return;
        }
        api
          .removeRoomMsg({
            id: n.id
          })
          .then((resp) => {
            util.message(resp.message);
            // this.messageList.splice(index,1) onmsg进行了处理
          })
          .catch((res) => {
            util.error(res.message);
            return;
          });
      } else if (n.messageType == 2) {
        api
          .removeGroupMsg({
            id: n.id
          })
          .then((resp) => {
            util.message(resp.message);
            this.removeMessage(n, index);
          })
          .catch((res) => {
            util.error(res.message);
            return;
          });
      } else if (n.messageType == 3 || n.messageType == 4) {
        api
          .removeMsg({
            id: n.id
          })
          .then((resp) => {
            util.message(resp.message);
            this.removeMessage(n, index);
          })
          .catch((res) => {
            util.error(res.message);
            return;
          });
      }
    },
    removeMessage(n, index) {
      let m = this.messageList2[index];
      if (m) {
        if (m.id == n.id) {
          this.messageList2.splice(index, 1);
          return;
        }
      }
      for (let i in this.messageList2) {
        m = this.messageList2[i];
        if (m) {
          if (m.id == n.id) {
            this.messageList2.splice(index, 1);
            return;
          }
        }
      }
    },
    ondel(n) {
      if (!this.delId) {
        this.delId = n.id;
        return;
      }
      if (this.delId == n.id) {
        this.delId = '';
        return;
      }
      if (this.delId != n.id) {
        this.delId = n.id;
        return;
      }
    },
    scroll() {
      if (this.showscrollinfo) {
        return;
      }
      this.$nextTick(function () {
        let el = this.$refs.msglist;
        if (!el) {
          return;
        }
        el.scrollTop = el.scrollHeight;
      });
    },
    onmsg(msg) {
      let l = msg;
      for (let i in l) {
        let r = l[i];
        if (r.type == vars.TYPE_VOTE_RESULT) {
          this.setVote = false;
          this.clearVote();
          continue;
        }
        if (r.type == vars.TYPE_VOTE_USER) {
          this.voteUser(r);
          // if (r.content == '1') {
          // 	r.content = '投了' + this.vote.optionA
          // } else {
          // 	r.content = '投了' + this.vote.optionB
          // }
          // this.messageList2.push(r)
          continue;
        }
        // this.$store.commit('messageNewTarget',r)
        if (
          r.type == vars.TYPE_REMOVE_MESSAGE &&
          r.messageType == this.talking.messageType
        ) {
          // sport.delMsg(this.messageList, r)
          continue;
        }
        if (r.type == vars.TYPE_LIVE_UPDATE) {
          continue;
        }
        if (r.senderId == this.$store.state.infoLogin.id) {
          continue;
        }
        if (r.type == vars.TYPE_LEAVE) {
          continue;
        }
        if (r.type == vars.TYPE_ENTER) {
          continue;
        }
        if (r.type == vars.TYPE_BANG) {
          continue;
        }
        if (
          r.messageType == 1 &&
          this.talking1.messageType == 1 &&
          r.roomId == this.talking1.id
        ) {
          if (this.showscrollinfo && (!this.divider || !this.divider.id)) {
            this.divider = {
              id: r.id,
              time: new Date().getTime()
            };
          }
          continue;
        }

        if (r.messageType == 2 && this.talking1.messageType == 2) {
          this.loadImg = r.id;
          if (this.showscrollinfo && (!this.divider || !this.divider.id)) {
            this.divider = {
              id: r.id,
              time: new Date().getTime()
            };
          }
          continue;
        }

        if (
          r.messageType == 3 &&
          this.talking1.messageType == 3 &&
          !r.assistId
        ) {
          this.loadImg = r.id;
          if (this.showscrollinfo && (!this.divider || !this.divider.id)) {
            this.divider = {
              id: r.id,
              time: new Date().getTime()
            };
          }
          continue;
        }

        if (
          r.messageType == 3 &&
          this.talking1.messageType == 4 &&
          r.assistId
        ) {
          this.loadImg = r.id;
          if (this.showscrollinfo && (!this.divider || !this.divider.id)) {
            this.divider = {
              id: r.id,
              time: new Date().getTime()
            };
          }
          continue;
        }
      }
    },
    clearVote() {
      this.isVote = false;
      this.vote = {
        title: '',
        optionA: '',
        optionB: '',
        status: 0,
        countA: 0,
        countB: 0
      };
    },
    onVote() {
      this.isVote = true;
    },
    voteUser(item) {
      this.vote.countA = item.optionA * 1;
      this.vote.countB = item.optionB * 1;
      this.count();
    },
    addMessage(item, prev) {
      let ni = item;
      if (item.type == vars.TYPE_VOTE_USER) {
        this.voteUser(item);
        if (item.content == '1') {
          item.content = '投了' + this.vote.optionA;
        } else {
          item.content = '投了' + this.vote.optionB;
        }
        this.messageList2.push(item);
        return;
      }
      if (item.type == vars.TYPE_VOTE) {
        return;
      }
      if (item.type == vars.TYPE_FOLLOW) {
        return;
      }
      if (this.talking1.messageType == 1 && ni.type == vars.TYPE_NOTICE) {
        if (ni.url) {
          this.talking1.joinMap.live.noticeUrl = ni.url;
          this.talking1.joinMap.live.notice = util.json(ni.content);
        } else {
          this.talking1.joinMap.live.notice = ni.content;
        }
      }
      if (item.contentType != 3) {
        let jsonContent = sport.jsonContent(item.content, item);
        item.jsonContent = jsonContent;
      }
      //messageType==1 直播间消息 2群组消息 3私聊消息
      if (
        item.messageType == 1 ||
        item.messageType == 2 ||
        item.messageType == 3
      ) {
        ni = Object.assign({}, item);
        let realTime = !item.addTime;
        if (realTime) {
          let c = item.content;
          Object.assign(ni, c);
        }
      }

      if (prev) {
        this.messageList2.unshift(ni);
      } else {
        this.messageList2.push(ni);
      }
    },
    clearInput() {
      this.showSale = false;
      this.form.at = [];
      if (this.form.contentType == 1) {
        this.form.content = '';
        this.form.copyContent = '';
      }
      if (this.isCopy) {
        this.form.copyContent = '';
      }
      this.form.contentType = 1;
      this.isCopy = false;
      this.form.url = '';
      this.form.thumb = '';
    },
    addDrawMessageList(r, id) {
      let key = id;
      if (this.talking1.messageType == 4) {
        key = `${this.talking1.messageType}-${id}`;
      }
      let map = this.$store.state.drawMap[key];
      if (map && map.id) {
        map.messageList.push(r);
      }
    },
    addDraw(id) {
      let key = id;
      if (this.talking1.messageType == 4) {
        key = `${this.talking1.messageType}-${id}`;
      }
      let map = this.$store.state.drawMap[key];
      if (map && map.id) {
        map.messageList = this.messageList;
        this.$set(this.$store.state.drawMap, key, map);
        try {
          let drawMapJSON = JSON.stringify(this.$store.state.drawMap);
          sessionStorage.setItem('drawMap', drawMapJSON);
        } catch (e) {
          console.error(e);
          //TODO handle the exception
        }
      }
    },
    checkSend(id) {
      let checkErr = '';
      if (!id) {
        checkErr = '请选择发送对象';
      } else if (!this.form.content || this.form.content.match(/^[ ]*$/)) {
        checkErr = '请输入内容';
      }

      //廣告的話 圖片、標題、連結 也都要必填
      if (this.active === 'advertisement') {
        if (!this.form.thumb) {
          checkErr = '请添加图片';
        } else if (!this.form.title) {
          checkErr = '请输入标题';
        } else if (!this.form.url) {
          checkErr = '请输入链接';
        }
      }
      return checkErr;
    },
    send() {
      if (this.loading) {
        return;
      }
      let f = this.talking1;
      let id = undefined;
      if (this.talking1.messageType == 1) {
        this.form.messageType = 1;
        id = f.id;
      }
      if (this.talking1.messageType == 2) {
        this.form.messageType = 2;
        id = f.groupId;
      }
      if (this.talking1.messageType == 3) {
        this.form.messageType = 3;
        id = f.friendId;
      }
      if (this.talking1.messageType == 4) {
        this.form.messageType = 3;
        id = f.friendId || f.senderId;
      }

      let err = this.checkSend(id);
      if (err !== '') {
        util.message(err);
        return;
      }
      this.loading = true;
      delete this.form.copyContent;
      let fd = Object.assign({}, this.form);

      if (this.active == 'advertisement') {
        fd.contentType = 2;
      }
      if (this.talking1.messageType == 1) {
        fd.roomId = id;
        delete fd.push;
        api
          .editRoomMessage(fd)
          .then((resp) => {
            this.loading = false;
            this.addMessage(resp.data);
            // if(this.istalking){
            //     let r = resp.data
            //     this.addDrawMessageList(r,r.roomId)
            // }
            this.clearInput();
            this.showscrollinfo = false;
            this.scroll();
            this.addDraw(id);
          })
          .catch((res) => {
            this.loading = false;
            this.isCopy = false;
            util.error(res.message);
          });
      }
      this.$set(
        f,
        'updateTime',
        util.dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
      );
      this.$set(f, 'lastMsg', fd.content);
      if (this.talking1.messageType == 2) {
        fd.groupId = id;
        api
          .editGroupMsg(fd)
          .then((resp) => {
            this.loading = false;
            this.showscrollinfo = false;
            this.addMessage(resp.data);
            // if(this.istalking){
            //     let r = resp.data
            //     this.addDrawMessageList(r,r.roomId)
            // }
            // istalking
            this.clearInput();
            this.scroll();
            this.form.push = 0;
            this.addDraw(id);
          })
          .catch((res) => {
            this.loading = false;
            this.isCopy = false;
            this.form.contentType = 1;
            util.error(res.message);
          });
      }
      if (this.talking1.messageType == 3 || this.talking1.messageType == 4) {
        delete fd.push;
        fd.tid = id;
        if (this.showType == 4) {
          fd.id = f.id;
        }
        api
          .editMsg(fd)
          .then((resp) => {
            this.loading = false;
            this.showscrollinfo = false;
            this.addMessage(resp.data);
            // if(this.istalking){
            //     let r = resp.data
            //     this.addDrawMessageList(r,r.receiverId)
            // }
            let r = resp.data;
            let u = this.$store.state.readMap[r.msgId];
            if (!u) {
              // this.$store.commit('unReadMsg',{r})
            }
            this.scroll();
            this.addDraw(id);
            // this.clearInput()
          })
          .catch((res) => {
            this.loading = false;
            this.isCopy = false;
            this.form.contentType = 1;
            util.error(res.message);
          });
      }
      if (fd.contentType == 1) {
        this.form.contentType = 1;
        this.form.content = '';
      }
      // this.loading = false
      this.form.thumb = '';
      this.form.title = '';
      this.form.url = '';
    },
    sendCopy() {
      if (this.copyLoading) {
        return;
      }
      if (!this.copyFile || !this.copyFile.name) {
        return;
      }
      let f = this.talking1;
      let id = undefined;
      if (this.talking1.messageType == 2) {
        this.form.messageType = 2;
        id = f.groupId;
      }
      if (this.talking1.messageType == 3) {
        this.form.messageType = 3;
        id = f.friendId;
      }
      if (this.showType == 4) {
        this.form.messageType = 3;
        id = f.friendId || f.senderId;
      }
      if (!id) {
        util.message('请选择发送对象');
        return;
      }
      if (this.copySize / 1024 / 1024 > 4) {
        this.$message.error('上传图片大小不能超过 4MB!');
        return;
      }
      this.copyLoading = true;
      this.isCopy = false;
      this.copyUrl = '';
      this.$nextTick(() => {
        if (this.$refs['content-' + this.talking1.id]) {
          this.$refs['content-' + this.talking1.id].focus();
        }
      });
      api
        .upload({ file: this.copyFile })
        .then((resp) => {
          this.form.copyContent = resp.data;
          this.form.contentType = 3;
          let fd = Object.assign({}, this.form);
          fd.content = this.form.copyContent;
          if (this.active == 'advertisement') {
            fd.contentType = 2;
          }
          this.$set(
            f,
            'updateTime',
            util.dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
          );
          this.$set(f, 'lastMsg', fd.content);
          delete this.form.copyContent;
          delete fd.copyContent;
          if (this.talking1.messageType == 2) {
            fd.groupId = id;
            api
              .editGroupMsg(fd)
              .then((resp) => {
                this.copyLoading = false;
                this.showscrollinfo = false;
                this.loadImg = resp.data.id;
                this.addMessage(resp.data);
                this.clearInput();
                this.scroll();
              })
              .catch((res) => {
                this.copyLoading = false;
                this.isCopy = false;
                this.loadImg = false;
                this.form.contentType = 1;
                util.error(res.message);
              });
          }
          if (
            this.talking1.messageType == 3 ||
            this.talking1.messageType == 4
          ) {
            fd.tid = id;
            if (this.showType == 4) {
              fd.id = f.id;
            }
            api
              .editMsg(fd)
              .then((resp) => {
                this.copyLoading = false;
                this.showscrollinfo = false;
                this.loadImg = resp.data.id;
                this.addMessage(resp.data);
                this.clearInput();
                this.scroll();
              })
              .catch((res) => {
                this.loading = false;
                this.isCopy = false;
                this.loadImg = false;
                this.form.contentType = 1;
                util.error(res.message);
              });
          }
        })
        .catch((err) => {
          this.isCopy = false;
          this.copyLoading = false;
          this.loadImg = false;
          util.error(err.message);
        });
    },
    beforeAvatarUpload(file) {
      this.file = file;
      let isJPG =
        file.type == 'image/png' ||
        file.type == 'image/jpg' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/bmp' ||
        file.type == 'image/gif';
      let isLt2M = file.size / 1024 / 1024 < 4;

      if (!isJPG) {
        this.$message.error('上传图片只能是 PNG、JPG、JPEG、BMP、GIF 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 4MB!');
      }
      return isJPG && isLt2M;
    },
    proUploader() {
      this.$emit('proUploader', true);
    },
    imgUploader(response, file, fileList) {
      if (file.response && file.response.data) {
        util.message('上传成功');
        let map = {
          content: file.response.data,
          type: 3
        };
        this.sendImage(map);
        this.$refs.upload.clearFiles();
      }
    },
    sendImage(map) {
      this.form.contentType = map.type;
      let f = this.talking1;
      let id = undefined;
      if (this.talking1.messageType == 1) {
        this.form.messageType = 1;
        id = f.id;
      }
      if (this.talking1.messageType == 2) {
        this.form.messageType = 2;
        id = f.groupId;
      }
      if (this.talking1.messageType == 3) {
        this.form.messageType = 3;
        id = f.friendId;
      }
      if (this.showType == 4) {
        this.form.messageType = 3;
        id = f.friendId || f.senderId;
      }
      if (!id) {
        util.message('请选择发送对象');
        return;
      }
      if (!this.form.thumb && this.form.title) {
        util.message('上传图片才可以设置标题');
        return;
      }

      let fd = Object.assign({}, this.form, { content: map.content });

      if (this.active == 'advertisement') {
        fd.contentType = 2;
      }
      this.$set(
        f,
        'updateTime',
        util.dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
      );
      this.$set(f, 'lastMsg', fd.content);
      if (this.talking1.messageType == 1) {
        fd.roomId = id;
        delete fd.push;
        api
          .editRoomMessage(fd)
          .then((resp) => {
            this.loading = false;
            this.addMessage(resp.data);
            // if(this.istalking){
            //     let r = resp.data
            //     this.addDrawMessageList(r,r.roomId)
            // }
            this.clearInput();
            this.showscrollinfo = false;
            this.scroll();
            this.$emit('proUploader', false);
            this.addDraw(id);
          })
          .catch((res) => {
            this.$emit('proUploader', false);
            this.loading = false;
            this.isCopy = false;
            util.error(res.message);
          });
      }
      if (this.talking1.messageType == 2) {
        fd.groupId = id;
        api
          .editGroupMsg(fd)
          .then((resp) => {
            this.loadImg = resp.data.id;
            this.showscrollinfo = false;
            this.addMessage(resp.data);
            this.scroll();
            this.$emit('proUploader', false);
          })
          .catch((res) => {
            this.$emit('proUploader', false);
            this.loadImg = false;
            util.error(res.message);
          });
      }
      if (this.talking1.messageType == 3 || this.talking1.messageType == 4) {
        fd.tid = id;
        if (this.showType == 4) {
          fd.id = f.id;
        }
        api
          .editMsg(fd)
          .then((resp) => {
            this.loadImg = resp.data.id;
            this.showscrollinfo = false;
            this.addMessage(resp.data);
            this.$emit('proUploader', false);
            this.scroll();
          })
          .catch((res) => {
            this.$emit('proUploader', false);
            this.loadImg = false;
            util.error(res.message);
          });
      }
      this.form.contentType = 1;
      this.showSale = false;
      this.form.at = [];
      this.form.thumb = '';
      this.form.title = '';
      this.form.url = '';
    },
    pasting(e) {
      let that = this; //改变this指向
      let text = (e.clipboardData || window.clipboardData).getData('text');
      if (text) {
        this.form.content = this.form.content.concat(text);
        return;
      }
      if (e.clipboardData.files && e.clipboardData.files.length) {
        //获取剪切板图片
        that.copyFile = e.clipboardData.files[0]; //该值可直接上传服务器
        if (!e || !window.FileReader) return; // 看是否支持FileReader
        let reader = new FileReader();
        reader.readAsDataURL(e.clipboardData.files[0]); //在这里转换的
        reader.onloadend = function () {
          if (!that.isIndexOfImg(that.copyFile.type)) {
            util.message('暂时只支持图片和文本的粘贴');
            return;
          }
          that.copySize = that.copyFile.size;
          that.isCopy = true;
          that.copyUrl = this.result;
        };
      }
    },
    endVote() {
      this.pageVoteing = true;
      api
        .editVote({
          id: this.vote.id,
          status: 0
        })
        .then((resp) => {
          this.vote.title = '';
          this.vote.status = 0;
          this.vote.optionA = '';
          this.vote.optionB = '';
          this.vote.countA = 0;
          this.vote.countB = 0;
          this.vote.id = '';
          this.vote.timeout = 0;
          this.countA = '50%';
          this.countB = '50%';
          this.setVote = false;
          this.pageVoteing = false;
          util.message(resp.message);
        })
        .catch((res) => {
          this.pageVoteing = false;
          util.error(res.message);
        });
    },
    // onVote() {
    // 	if (!this.vote.title) {
    // 		util.message("请输入投票主题")
    // 		return
    // 	}
    // 	if (!this.vote.optionA && !this.vote.optionB) {
    // 		util.message("请输入选项A或选项B")
    // 		return
    // 	}
    // 	this.vote.roomId = this.talking.id
    // 	this.vote.status = 1
    // 	api.editVote(this.vote).then(resp => {
    // 		this.vote.id = resp.data.id
    // 		util.message(resp.message)
    // 	}).catch(res => {
    // 		util.error(res.message)
    // 	})
    // },
    primaryVote() {
      if (!this.vote.title) {
        util.message('请输入投票主题');
        return;
      }
      if (!this.vote.optionA && !this.vote.optionB) {
        util.message('请输入选项A或选项B');
        return;
      }
      if (this.vote.date && this.vote.date.length > 1) {
        let onTime = this.vote.date[0];
        let endTime = this.vote.date[1];
        this.vote.timeout = (Date.parse(endTime) - Date.parse(onTime)) / 1000;
        this.vote.beginTime = util.dateFormat(onTime, 'yyyy-MM-dd HH:mm:ss');
        this.vote.endTime = util.dateFormat(endTime, 'yyyy-MM-dd HH:mm:ss');
      }
      this.vote.roomId = this.talking.id;
      this.vote.status = 1;
      this.primaryVoteing = true;
      api
        .editVote(this.vote)
        .then((resp) => {
          this.vote.id = resp.data.id;
          this.setVote = true;
          this.isVote = false;
          util.message(resp.message);
          this.primaryVoteing = false;
        })
        .catch((res) => {
          this.primaryVoteing = false;
          util.error(res.message);
        });
    },
    pageVote() {
      this.pageVoteing = true;
      api
        .pageVote({
          roomId: this.talking1.id,
          status: 1
        })
        .then((resp) => {
          this.pageVoteing = false;
          if (resp.data.list && resp.data.list.length != 0) {
            this.vote = resp.data.list[0];
            this.setVote = true;
            this.count();
            // this.vote.countA=20;
            // this.vote.countB=10;
            return;
          }
          this.setVote = false;
          this.vote = {};
        })
        .catch((res) => {
          this.vote = {};
          this.setVote = false;
          this.pageVoteing = false;
        });
    },
    count() {
      let count = this.vote.countA + this.vote.countB;
      if (count != 0) {
        this.countA = (this.vote.countA / count) * 100 + '%';
        this.countB = (this.vote.countB / count) * 100 + '%';
      } else {
        this.countA = '50%';
        this.countB = '50%';
      }
    },
    clickHandler(i) {
      if (this.form.content) {
        this.form.content += i;
      } else {
        this.form.content = i;
      }
      this.setUpFocus();
      this.iconshow = false;
    },
    isEmoj(content) {
      let reg =
        /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
      if (content.match(reg)) {
        return true;
      }
      return false;
    },
    handleClick(e, n) {
      if (this.talking1.messageType != 1 && n == 'vote') {
        e.preventDefault();
        return;
      }
      this.active = n;
      this.form.contentType = 1;
      this.form.thumb = '';
      this.form.title = '';
      this.form.url = '';
      if (n == 'vote') {
        this.pageVote();
      }
    },
    msgLeft(n) {
      return !this.msgRight(n);
    },
    msgRight(n) {
      if (this.showType == 4) {
        if (n.left) {
          return false;
        }
        if (n.right) {
          return true;
        }
        if (n.receiverId == this.talking.friendId) {
          return true;
        }
        return false;
      }
      if (n.senderId == this.$store.state.infoLogin.id) {
        return true;
      }
      return false;
    },
    time(date) {
      let r = util.dateFormat(date, 'MM-dd HH:mm:ss');
      return r;
    },
    keyboardDelete(e) {
      if (this.isCopy) {
        this.isCopy = false;
        this.copyUrl = '';
        this.form.contentType = 1;
        this.$nextTick(() => {
          if (this.$refs['content-' + this.talking1.id]) {
            this.$refs['content-' + this.talking1.id].focus();
          }
        });
      }
    },
    getGroupUser(t) {
      //    if(this.groupUserlist && this.groupUserlist.length>0){
      //        this.atUserShow = true
      //        return
      //    }
      // let groupId = t.groupId
      // this.guestForm.groupId = groupId
      // api.listGroupUser(this.guestForm).then(resp => {
      this.atUserShow = true;
      // 	if (!resp.data || !resp.data.length) {
      // 		return
      // 	}
      //     let list = resp.data
      // 	this.groupUserlist = list
      // }).catch(err => {
      // 	util.error('无法获取数据')
      // })
    },
    onCompositionStart(event) {
      // 輸入框內開啟輸入法，且正在拼字時觸發。
      this.isComposing = true;
    },
    onCompositionEnd(event) {
      // 輸入框內開啟輸入法，拼字或選字完成，正要送出至輸入框時觸發。
      this.isComposing = false;
      this.form.content = event.target.value; // Update v-model manually
    },
    onkeyup(a) {
      if (this.isComposing) {
        return; // Skip if composing
      }
      this.isEnter = localStorage.getItem('isEnter') == 1 ? true : false;
      if (this.isEnter) {
        if (a.ctrlKey && a.keyCode == 13) {
          this.send();
          return;
        }
      }
      if (!this.isEnter) {
        if (a.ctrlKey && a.keyCode == 13) {
          this.form.content += '\n';
          return;
        }
        if (a.key == 'Enter' && !a.ctrlKey) {
          a.preventDefault();
          this.send();
          return true;
        }
      }
      if (a.key == '@') {
        if (this.talking1.messageType == 2) {
          this.getGroupUser(this.talking1);
        }
        return;
      }
      if (a.key == '2') {
        let val = a.target.value;
        if (/@$/g.test(val)) {
          if (this.talking1.messageType == 2) {
            this.getGroupUser(this.talking1);
          }
          return;
        }
      }
      if (this.atUserShow) {
        let el = a.target;
        let val = el.value;
        let ei = val.lastIndexOf('@');
        if (ei == -1) {
          this.atUserShow = false;
          return;
        }
        this.atUserWord = val.substr(ei + 1);
      }
    },
    shareImage(n) {
      this.$emit('onShareImage', n);
    }
  }
};
</script>

<style lang="less">
.el-divider__text {
  background-color: #f8f8f8;
}
.perinfo-tag-btn .el-input--mini .el-input__inner {
  flex: 1;
  height: 25px;
  line-height: 25px;
  border: 0px;
  padding-left: 5px;
}
.perinfo-tag-input {
  width: 250px;
  display: flex;
  border: 1px solid #ebeef5;
  border-radius: 3px;
}
.perinfo-tag-btn .el-button {
  padding: 0 5px;
  border: 0px;
  color: #04c160;
}
.perinfo-tag-btn .el-button:hover,
.tag-btn .el-button:focus {
  border: 0px;
  background-color: #fff;
  color: #04c160;
}
.agmsg-body-item-char-img .el-image__placeholder {
  background: #e8e8e8 !important;
}
</style>
<style lang="less" scoped="scoped">
@import url('../style/message1.less');
::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.cancel {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
</style>
