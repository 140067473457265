<template>
  <!-- layout="total,  prev, pager, next, jumper, sizes" -->
  <el-pagination
    :layout="layout1"
    small
    :total="total"
    :page-size="form.size"
    :current-page="form.page"
    @current-change="pageChange"
    @size-change="sizeChange"
    background
    :page-sizes="[1, 5, 10, 15, 20, 50, 90, 100, 500]"
  />
</template>

<script>
export default {
  data() {
    return {
      layout1: 'total,  prev, pager, next, sizes, jumper'
    };
  },
  props: ['form', 'total', 'change', 'layout'],
  created() {},
  computed: {},
  mounted() {
    if (this.layout) {
      this.layout1 = this.layout;
    }
  },
  methods: {
    pageChange(val) {
      this.form.page = val;
      this.$emit('change', val);
    },
    sizeChange(val) {
      this.form.size = val;
      this.$emit('change', val);
    }
  }
};
</script>

<style lang="less"></style>
