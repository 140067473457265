if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.warn = () => {};
}
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store/';
import util from './config/util.js';

import ElementUI from 'element-ui';
Vue.use(ElementUI);

// import VCharts from 'v-charts'
// Vue.use(VCharts)

// import  VueQuillEditor from 'vue-quill-editor'
// Vue.use(VueQuillEditor)

import agPage from '@/components/agPage';
import agSelect from '@/components/agSelect';

import agTree from '@/components/agTree';
import agBranch from '@/components/agBranch';
import uploader from '@/components/uploader';
import multipleUploader from '@/components/multipleUploader';
import agLink from '@/components/agLink';
// import country from '@/components/country'
Vue.component('ag-page', agPage);
Vue.component('ag-select', agSelect);
Vue.component('ag-tree', agTree);
Vue.component('ag-branch', agBranch);
Vue.component('ag-uploader', uploader);
Vue.component('ag-muploader', multipleUploader);
Vue.component('ag-link', agLink);
// Vue.component('ag-country', country)
import wordTop from '@/components/wordTop';
Vue.component('ag-word-top', wordTop);

import wordBottom from '@/components/wordBottom';
Vue.component('ag-word-bottom', wordBottom);

import agCheckbox from '@/components/agCheckbox';
Vue.component('ag-checkbox', agCheckbox);

import agRadio from '@/components/agRadio';
Vue.component('ag-radio', agRadio);

import agTransfer from '@/components/agTransfer';
Vue.component('ag-transfer', agTransfer);

import spEditGroup from '@/components/spEditGroup';
Vue.component('sp-edit-group', spEditGroup);

import agSelectRemote from '@/components/agSelectRemote';
Vue.component('ag-select-remote', agSelectRemote);

import agConfigure from '@/components/agConfigure';
Vue.component('ag-configure', agConfigure);
import agMsg from '@/components/agMsg.vue';
Vue.component('ag-msg', agMsg);
import agInfoDetail from '@/components/agInfoDetail.vue';
Vue.component('ag-info-detail', agInfoDetail);
import agAuthor from '@/components/agAuthor';
Vue.component('ag-author', agAuthor);
import agUserList from '@/components/agUserList';
Vue.component('ag-user-list', agUserList);

import agList from '@/components/agList';
Vue.component('ag-list', agList);

import agHlsVideo from '@/components/agHlsVideo';
Vue.component('ag-hls-video', agHlsVideo);

import agVideoControl from '@/components/agVideoControl';
Vue.component('ag-video-control', agVideoControl);

import '../static/el/index.css';
import '../static/fonts/iconfont.css';
import './style/default.less';

Vue.filter('url', function (value) {
  return util.url(value);
});

Vue.filter('image', function (value) {
  return util.url(value);
});

Vue.config.productionTip = false;
window.app = new Vue({
  // el: '#app',
  router,
  store,
  template: '<App/>',
  render: (h) => h(App)
  // components: {App}
}).$mount('#app');
