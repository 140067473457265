<template>
  <div>
    <table style="border-collapse: collapse; border: none; width: 100%">
      <tr>
        <td
          style="
            width: 200px;
            text-align: center;
            border: 1px solid #dcdfe6;
            border-top: none;
          "
        >
          {{ department }}
          <br />
          审核
        </td>
        <td
          style="
            height: 50px;
            text-align: right;
            border: 1px solid #dcdfe6;
            border-top: none;
          "
        >
          <br />

          <span style="margin-right: 10px">
            审核人：<span
              style="
                display: inline-block;
                width: 100px;
                border-bottom: 1px solid #fff;
              "
            >
              {{ name }}
            </span>
          </span>
          <span style="margin-right: 10px">
            <span
              style="
                display: inline-block;
                width: 40px;
                border-bottom: 1px solid #fff;
              "
            >
              {{ year }}
            </span>
            年
            <span
              style="
                display: inline-block;
                width: 20px;
                border-bottom: 1px solid #fff;
              "
            >
              {{ month }}
            </span>
            月
            <span
              style="
                display: inline-block;
                width: 20px;
                border-bottom: 1px solid #fff;
              "
            >
              {{ date }}
            </span>
            日
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    department: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: new Date().getDate(),
      name: ''
    };
  },
  watch: {
    title(val) {}
  },
  mounted() {}
};
</script>

<style lang="less"></style>
