<template>
  <el-select
    ref="select"
    :class="[error === true ? 'el-select-error' : '']"
    v-model="val"
    :clearable="true"
    :filterable="true"
    :size="size"
    :loading="loading"
    :multiple="multiple"
    :disabled="disabled"
    :no-data-text="noDataText"
    :placeholder="placeholder"
    @change="change"
    @visible-change="visibleChange"
  >
    <el-option
      v-for="(item, index) in data"
      :key="item[name] + '-' + index"
      :label="item[label]"
      :value="item[name] || index"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: {},
    data: {
      type: Array
    },
    placeholder: {
      default: '请选择'
    },
    multiple: {
      default: false
    },
    disabled: {
      default: false
    },
    name: {
      default: 'id'
    },
    label: {
      default: 'name'
    },
    size: {
      default: 'mini'
    },
    loading: {
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      //temp:this.uin(this.data),

      val: undefined
    };
  },
  watch: {
    val(value) {
      //触发改变v-model的值
      this.$emit('input', value, this.val);
    },
    value(value) {
      this.val = value === 0 ? 0 : value || undefined;
    }
  },
  // created() {},
  mounted() {
    //传入的变量不能改变, 所以新建一个内部变量
    this.val = this.value === 0 ? 0 : this.value || undefined;
  },
  // destroyed() {},
  methods: {
    change(val) {
      this.$emit('change', val);
    },
    visibleChange(val) {
      const cleanQuery = () => {
        this.$refs.select.query = '';
        this.$refs.select.options.forEach((item) => {
          item.visible = true;
        });
      };
      this.$emit('visibleChange', val, cleanQuery);
    },
    uin(arr) {
      let hash = {};

      if (!Array.isArray(arr)) {
        return [];
      }
      const newArr = arr.reduceRight((item, next) => {
        hash[next.id] ? '' : (hash[next.id] = true && item.push(next));
        return item;
      }, []);
      return newArr;
    }
  }
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
  /deep/ .el-input--mini .el-input__inner {
    transition: border-color 0.3s, background-color 0.3s;
  }
  /deep/ input::placeholder,
  /deep/ .el-select__caret.el-input__icon.el-icon-arrow-up {
    transition: color 0.3s;
  }
  &-error {
    /deep/ .el-input--mini .el-input__inner {
      border-color: #ff7979;
      background-color: #fff5f5;
    }
    /deep/ input::placeholder,
    /deep/ .el-select__caret.el-input__icon.el-icon-arrow-up {
      color: #ff7979;
    }
  }
}
</style>
