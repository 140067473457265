<template>
  <el-cascader
    style="width: 100%"
    :value="value"
    :props="props"
    :options="options"
    :placeholder="placeholder"
    @change="change"
    :filterable="true"
    :clearable="true"
  ></el-cascader>
</template>

<script>
import { mapState } from 'vuex';
let id = 0;

import api from '../api/api.js';
import util from '@/config/util';

export default {
  props: {
    value: {},
    data: {
      type: Array
    },
    placeholder: {
      default: '请选择'
    },
    multiple: {
      default: false
    },
    name: {
      default: 'id'
    },
    label: {
      default: 'name'
    },
    size: {
      default: 'mini'
    }
  },
  mounted() {
    //传入的变量不能改变, 所以新建一个内部变量
    this.val = this.value || undefined;
    console.log('ag-branch', this.$store.treeCourse, this.$store.state.system.treeCourse);
  },
  data() {
    return {
      listBranch: [],
      val: undefined,
      props: {
        value: 'id',
        label: 'name'
        // lazy: true,
        // lazyLoad (node, resolve) {
        //           let {level, value} = node
        //           let func
        //           if(level==0){
        //               func = api.listBranch
        //           }else{
        //               func = api.listCourseByBranch
        //           }
        //           func({branchId: value}).then(resp=>{
        //               let nodes = resp.data.map(i=>({
        //                   value: i.id,
        //                   label: i.name,
        //                   leaf: level==1
        //               }))
        //               resolve(nodes);
        //           }).catch(res=>{
        //                 util.error(res.message)
        //           })

        // }
      }
    };
  },
  created() {
    //console.log('ag-select created')
  },
  watch: {
    val(value) {
      //触发改变v-model的值
      //console.log('ag-select emit input', value)
      this.$emit('input', value);
    },
    value(value) {
      this.val = value || undefined;
    }
  },
  computed: {
    ...mapState({
      options: (state) => state.system.treeCourse || []
    }),
  },
  destroyed() {
    console.log('ag-select destroyed');
  },
  methods: {
    change(val) {
      this.$emit('input', val && val.length > 1 ? val[1] : undefined);
    },
    uin(arr) {
      let hash = {};

      if (!Array.isArray(arr)) {
        return [];
      }
      const newArr = arr.reduceRight((item, next) => {
        hash[next.id] ? '' : (hash[next.id] = true && item.push(next));
        return item;
      }, []);
      return newArr;
    }
  }
};
</script>

<style lang="less">
.el-select {
  width: 100%;
}
</style>
