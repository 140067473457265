<template>
  <div>
    <div
      style="
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        line-height: 44px;
        padding: 10px;
      "
    >
      {{ title }}
    </div>

    <div style="line-height: 40px; padding: 10px; text-align: center">
      <span v-if="hasDepartment" style="margin-right: 10px">
        申请部门：<span style="display: inline-block">
          {{ department }}
        </span>
      </span>
      <span v-if="hasCompany" style="margin-right: 10px">
        单位名称（盖章）：<span style="display: inline-block">
          {{ company }}
        </span>
      </span>
      <span style="margin-right: 10px">
        填表人：<span style="display: inline-block">
          {{ name }}
        </span>
      </span>
      <span style="margin-right: 10px">
        联系电话：<span style="display: inline-block">
          {{ phone }}
        </span>
      </span>

      <span style="margin-right: 10px">
        填表日期：
        <span style="display: inline-block">
          {{ year }}
        </span>
        年
        <span style="display: inline-block">
          {{ month }}
        </span>
        月
        <span style="display: inline-block">
          {{ date }}
        </span>
        日
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    hasDepartment: {
      type: Boolean,
      default: false
    },
    hasCompany: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: new Date().getDate(),
      company: '__________________',
      department: '__________________',
      name: '__________________',
      phone: '__________________'
    };
  },
  watch: {
    title(val) {
      //this.tit = val
    }
  },
  mounted() {}
};
</script>

<style lang="less"></style>
