import { render, staticRenderFns } from "./agMsg.vue?vue&type=template&id=5c0ae146&scoped=true"
import script from "./agMsg.vue?vue&type=script&lang=js"
export * from "./agMsg.vue?vue&type=script&lang=js"
import style0 from "./agMsg.vue?vue&type=style&index=0&id=5c0ae146&prod&lang=less"
import style1 from "./agMsg.vue?vue&type=style&index=1&id=5c0ae146&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0ae146",
  null
  
)

export default component.exports