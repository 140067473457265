import {
  baseUrl,
  downloadUrl,
  brand,
  verIndex,
  channel,
  timeout
} from './env.js';
import axios from 'axios';
import Vue from 'vue';
import router from '@/router';
import sha256 from 'js-sha256';
import random from 'string-random';

let config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  },
  timeout: timeout
};

const util = {};
util.dt = 5;
util.url = (url) => {
  if (!url) {
    return '';
  }
  if (url.indexOf('http') == 0) {
    return url;
  }

  return downloadUrl + 'file/' + url;
};

util.image = (url) => {
  if (!url) {
    return '';
  }
  if (url.indexOf('http') == 0) {
    return url;
  }

  return downloadUrl + 'image/' + url;
};

util.vue = new Vue();

util.emit = function (name, args) {
  util.vue.$emit(name, args);
};

util.on = function (name, func) {
  util.vue.$on(name, func);
};

util.off = function (name, func) {
  util.vue.$off(name, func);
};

util.api = baseUrl;
// util.api = 'http://api.ishang751.com/api'
util.notify = function (title, message) {
  window.app.$notify({
    title,
    message
  });
};

util.confirm = function (title, func, callback) {
  window.app.$alert(title, {
    confirmButtonText: '确定',
    callback: function (action) {
      if (action == 'confirm') {
        if (typeof callback === 'function') {
          func().then(callback);
        } else {
          func();
        }
      }
    }
  });
};

util.message = function (message) {
  window.app.$message(message);
  if (message === '无权限') {
    window.app.$router.push({
      path: '/manage',
      query: { errMsg: '访问页面无权限，回到主画面' }
    });
  }
};

util.success = function (message) {
  window.app.$message.success(message);
};
util.error = function (message) {
  window.app.$message.warning(message);
};
util.warning = function (message) {
  window.app.$message.warning(message);
};

const dateFormat = (date, fmt = 'yyyy-MM-dd') => {
  if (!date) {
    return '';
  }
  if (typeof date == 'string') {
    date = date.replace(/-/g, '/');
  }
  date = new Date(date);

  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'S+': date.getMilliseconds()
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(String(o[k]).length)
      );
    }
  }
  return fmt;
};
util.dateFormat = dateFormat;

util.formatNumber = function (n) {
  return n > 9 ? n.toString() : `0${n}`;
};

util.formatTime = function (date) {
  if (typeof date == 'string') {
    let ar = date.split(/[-:\s]+/);
    return ar[3] + ':' + ar[4];
  }

  date = new Date(date);

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const time = [hour, minute].map(util.formatNumber).join(':');

  return time;
};

util.formatDateTime = function (date) {
  if (typeof date == 'string') {
    let ar = date.split(/[-:\s]+/);
    return ar[1] + '-' + ar[2] + ' ' + ar[3] + ':' + ar[4] + ':' + ar[5];
  }

  date = new Date(date);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const d = [year, month, day].map(util.formatNumber).join('-');
  const time = [hour, minute, second].map(util.formatNumber).join(':');

  return `${d} ${time}`;
};

util.formatDate = function (date) {
  if (typeof date == 'string') {
    let ar = date.split(/[-:\s]+/);
    return ar[1] + '月' + ar[2] + '日';
  }

  date = new Date(date);

  // const year = date.getFullYear()
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // const d = [year, month, day].map(util.formatNumber).join('-')
  console.log(month, day);
  return `${month}月${day}日`;
};
util.forTypeDate = function (date) {
  if (typeof date == 'string') {
    let ar = date.split(/[-:\s]+/);
    return ar[1] + '/' + ar[2];
  }

  date = new Date(date);

  // const year = date.getFullYear()
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // const d = [year, month, day].map(util.formatNumber).join('-')

  return `${month}/${day}`;
};
util.formatDay = function (date) {
  // if(typeof(date) == 'string'){
  // 	let ar = date.split(/[-:\s]+/)
  // 	return ar[1]+'月'+ar[2]+'日'
  // }

  date = new Date(date);
  const day = date.getDay();

  // const d = [year, month, day].map(util.formatNumber).join('-')

  return vars.day[day];
};
util.parseDate = (str) => {
  if (!str) {
    return '';
  }
  if (typeof str == 'string') {
    str = str.replace(/-/g, '/');
  }
  let d = new Date(str);
  return d;
};

util.simpleDateTime = (date) => {
  if (!date) {
    return '';
  }
  if (typeof date == 'string') {
    date = date.replace(/-/g, '/');
  }
  date = new Date(date);

  let dt = date.getTime();
  let ndt = new Date().getTime();
  if (ndt - dt < 24 * 3600 * 1000) {
    return dateFormat(date, 'HH:mm');
  }
  return dateFormat(date, 'yyyy-MM-dd');
};

util.postTime = {};

const apisecret = '526dc69734aaacba8481c479f3cb2cd7';
const apiSignName = 'hash';
function urlsecret(method, rand, token) {
  const ts = Math.floor(Date.now() / 1000);
  let deviceType = util.dt;
  let data = `${brand}-${deviceType}-${verIndex}-${channel}-${token}-${ts}`;
  let hash = sha256.sha256(data + rand + apisecret);
  return data + '-' + hash;
}

util.post = function (method, data) {
  // let last = util.postTime[method]
  // let now = new Date().getTime()
  // if(last && last > now - 500 ){
  //     console.error("postTime")
  //     return new Promise(async (resolve, reject)=>{
  //          reject({message: '太快了'})
  //     })
  // }
  // util.postTime[method] = now

  let params = new FormData();
  if (data) {
    for (let k in data) {
      let v = data[k];

      if (v === undefined) {
        continue;
      }

      // if(v instanceof Boolean){
      //     params.append(k, v ? 1 : 0)
      // }else
      if (v instanceof Date) {
        if (/Time$/.test(k)) {
          params.append(k, dateFormat(v, 'yyyy-MM-dd HH:mm:ss'));
        } else {
          params.append(k, dateFormat(v));
        }
      } else if (v instanceof Array) {
        for (let i = 0; i < v.length; i++) {
          params.append(k, v[i]);
        }
      } else {
        params.append(k, v);
      }
    }
  }
  let token = localStorage.token || '';
  let api = util.api;
  let link = api.indexOf('?') == -1 ? '?' : '&';
  let rd = random();
  let url = `${link}method=${method}`; //&random=${rd} &verison=${env.verIndex}
  // url = urlsecret(url, method, rd)
  api += url; // + '&token=' + token
  config.headers.token = token;
  config.headers.aghash = urlsecret(method, rd, token);

  return new Promise(async (resolve, reject) => {
    axios
      .post(api, params, config)
      .then((res) => {
        //   console.log('api', method, data || '{}', res.data)

        let resp = res.data;
        if (resp.success) {
          resolve(resp);
        } else {
          if (resp.message == '未登录') {
            util.message(resp.message);
            location.href = '/';
            sessionStorage.removeItem('drawMap');
            sessionStorage.removeItem('drawRegionMap');
            return;
          }
          reject(resp);
        }
      })
      .catch((res) => {
        console.error('api', method, data || '{}', res);
        reject({ message: '网络异常' });
      });
  });
};

util.arrayIndexOf = function (arr, val) {
  for (let i in arr) {
    if (arr[i] == val) {
      return i;
    }
  }
};

util.arrayToggle = function (arr, val) {
  let i = util.arrayIndexOf(arr, val);
  console.log('arr', arr);
  if (i < 0) {
    arr.push(row.id);
  } else {
    arr.splice(i, 1);
  }
};

util.arraySet = function (arr, val) {
  let i = util.arrayIndexOf(arr, val);
  if (i < 0) {
    arr.push(val);
  }
};

util.asTree = (list) => {
  let map = {};
  for (let i in list) {
    let r = list[i];
    map[r.id] = r;
  }

  let t = [];
  for (let i in list) {
    let r = list[i];
    if (r.parentId == 0) {
      t.push(r);
    } else {
      let p = map[r.parentId];
      // console.log('p',p);
      if (!p) p = {};
      if (!p.children) {
        p.children = [];
      }
      p.children.push(r);
    }
  }

  return t;
};

util.getFirstTimeOfMonth = (date = new Date()) => {
  let firstDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
  return firstDate;
};

util.getTimeOfMonth = (date = new Date(), t = 0) => {
  let firstDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - t,
    0,
    0,
    0
  );
  return firstDate;
};
util.getLastTimeOfMonth = (date = new Date()) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month == 12) {
    month = 0;
    year += 1;
  }

  let lastDate = new Date(year, month, 1, 0, 0, 0);
  lastDate.setTime(lastDate.getTime() - 1000);
  return lastDate;
};

util.getTimeOfWeek = (d, date = new Date()) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month == 12) {
    month = 0;
    year += 1;
  }

  let lastDate = new Date(year, month, 0, 0, 0, 0);
  let day = 1000 * 60 * 60 * 24 * d;
  lastDate.setTime(lastDate.getTime() + day);
  return lastDate;
};

util.dateRange = (firstDate, lastDate) => {
  const dt = 1000 * 24 * 60 * 60;
  let dates = [];
  let date = new Date(firstDate);
  for (; date <= lastDate; ) {
    dates.push(util.dateFormat(date));
    date.setTime(date.getTime() + dt);
  }
  return dates;
};

util.listMonth = (list, keyfun, valfun, datefun, sumfun) => {
  if (!list || list.length == 0) {
    return [];
  }

  let keys = {};
  let result = [];
  for (let i in list) {
    let r = list[i];
    let key = keyfun(r);
    let nr = keys[key];

    let val = valfun(r);
    let date = datefun(r);

    if (!nr) {
      nr = { name: key };
      keys[key] = nr;
      nr[date] = val;
      result.push(nr);
    } else {
      let ov = nr[date];
      if (!ov) {
        console.log('sumfun1', date, ov, val);
        nr[date] = val;
      } else {
        console.log('sumfun2', date, ov, val);
        sumfun(ov, val);
      }
    }
  }

  console.log(result);
  return result;
};
(function () {
  window.parameterMap = {};
  let search = location.search;
  if (!search) {
    return;
  }
  let keyValues = search.substr(1).split('&');
  for (let i in keyValues) {
    let kvs = keyValues[i];
    let kv = kvs.split('=');
    let k = decodeURIComponent(kv[0]);
    let v = decodeURIComponent(kv[1]);
    window.parameterMap[k] = v;
  }
})();

util.pie = function (title, data) {
  // [
  //     {value:3135, name:'未收集'},
  //     {value:31110, name:'已收集'},
  // ]

  let types = [];
  let total = 0;
  for (let i in data) {
    types.push(data[i].name);
    total += data[i].value;
  }

  for (let i in data) {
    data[i].name =
      data[i].name + ' ' + ((data[i].value * 100) / total).toFixed(2) + '%';
  }

  let option = {
    title: {
      text: title,
      subtext: title,
      x: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: types
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  return option;
};

util.pies = function (title, data, t = 1) {
  let types = [];
  for (let i in data) {
    types.push(data[i].name);
  }
  let color = [];
  if (t == 1) {
    color = ['#4472c4', '#ed7d31', '#a5a5a5', '#ffc001', '#5b9bd5'];
  }
  let option = {
    title: {
      text: title,
      subtext: '',
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b} <br/> {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      top: 'center',
      left: 'right',
      align: 'left',
      data: types
    },
    series: [
      {
        name: data.name,
        type: 'pie',
        color: color,
        radius: '45%',
        data: data
      }
    ]
  };
  return option;
};

util.bar = function (title, data) {
  if (data.length < 1) {
    return;
  }

  let f = data[0];

  let legend = [];
  let series = [];
  let map = {};
  for (let name in f) {
    if (name == 'name') {
      continue;
    }
    legend.push(name);
    let serie = {
      name,
      type: 'bar',
      data: []
    };
    series.push(serie);
    map[name] = serie;
  }
  console.log('legend', legend);
  console.log('series', series);
  console.log('map', map);

  let xAxis = [];
  for (let i in data) {
    let date = data[i].name;
    xAxis.push(date);
  }

  console.log('xAxis', xAxis);

  for (let i in data) {
    let r = data[i];
    console.log('r', r);
    for (let j in legend) {
      let name = legend[j];
      map[name].data.push(r[name]);
    }
  }

  console.log('map', map);
  console.log('series', series);

  let option = {
    color: ['#a3e1d4', '#69c2f9'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    legend: {
      data: legend
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: xAxis
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: series
  };
  return option;
};

util.line = function (title, data) {
  let xAxis = [];
  for (let i in data) {
    let r = data[i];
    let v = 0;
    if (i == 0) {
      v =
        (new Date(r.minute).getTime() - new Date(r.minute).getTime()) /
          1000 /
          60 +
        5;
    } else {
      let r1 = data[i - 1];

      v =
        (new Date(r.minute).getTime() - new Date(r1.minute).getTime()) /
          1000 /
          60 +
        i * 5;
      // console.log(v)
      // let minute = util.formatTime(r.minute)
    }
    xAxis.push(v);
  }
  let option = {
    title: {
      text: title
    },
    tooltip: {
      trigger: 'axis',
      formatter: '{b}分：{c}',
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'line',
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    legend: {},
    toolbox: {},
    xAxis: {
      type: 'category',
      data: xAxis,
      boundaryGap: false,
      name: '直播时间',
      nameTextStyle: {
        color: '#000'
      },
      axisLine: {
        symbol: ['none', 'arrow']
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}'
      },
      name: '在线人数',
      nameTextStyle: {
        color: '#000'
      }
    },
    series: [
      {
        name: '',
        type: 'line',
        data: data,
        symbolSize: 8,
        itemStyle: {
          normal: {
            color: '#409eff',
            lineStyle: {
              color: '#409eff'
            }
          }
        },
        markPoint: {
          symbol: 'pin',
          data: [
            {
              type: 'max',
              name: 'Max',
              label: {
                normal: {
                  formatter: ['{a|峰值}'].join('\n'),
                  rich: {
                    a: {
                      color: '#fff',
                      lineHeight: 10
                    }
                  }
                }
              }
            },
            {
              type: 'min',
              name: 'Min',
              label: {
                normal: {
                  formatter: ['{a|最低}'].join('\n'),
                  rich: {
                    a: {
                      color: '#fff',
                      lineHeight: 10
                    }
                  }
                }
              }
            }
          ]
        },
        markLine: {
          data: [{ type: 'average', name: 'Avg' }],
          label: {
            normal: {
              formatter: ['{a|平均：{c}}'].join('\n'),
              rich: {
                a: {
                  color: '#409eff',
                  lineHeight: 10
                }
              }
            }
          }
        }
      }
    ]
  };
  return option;
};

util.json = (str) => {
  if (
    str &&
    typeof str == 'string' &&
    str.charAt(0) == '{' &&
    str.charAt(str.length - 1) == '}'
  ) {
    try {
      // console.log('is json string', str)
      return JSON.parse(str);
    } catch (e) {
      console.log('util.json', e);
    }
  } else {
    // console.log('not jsonstring', str)
  }
  return str;
};
util.arrayName = (idList, arrayList) => {
  let list = {};
  for (let i in idList) {
    let r = idList[i];
    list[r.id] = r.name;
  }
  let name = '';
  for (let i in arrayList) {
    let r = arrayList[i];
    name += list[r] + ' ';
  }
  return name;
};

util.isEqualList = function (oldList, newList) {
  if (!newList || !newList.length) {
    return [];
  }
  if (!oldList || !oldList.length) {
    return newList;
  }
  let listMap = {};
  for (let i in newList) {
    let r = newList[i];
    listMap[r.id] = r;
  }
  let list = [];
  for (let i in oldList) {
    let r = oldList[i];
    let or = listMap[r.id];
    if (!or || !or.id) {
      continue;
    }
    if (!util.isEqual(or, r)) {
      list.push(or);
      continue;
    } else {
      list.push(r);
    }
  }
  let oldlistMap = {};
  for (let i in oldList) {
    let r = oldList[i];
    oldlistMap[r.id] = r;
  }
  for (let i in newList) {
    let r = newList[i];
    let or = oldlistMap[r.id];
    if (!or || !or.id) {
      list.push(r);
      continue;
    }
  }
  return list;
};

util.getSessionStorage = function (key) {
  let value = sessionStorage.getItem(key);
  if (value) {
    try {
      let valueJSON = JSON.parse(value);
      if (Object.values(valueJSON).length > 0) {
        return valueJSON;
      }
    } catch (e) {
      //TODO handle the exception
      return value;
    }
  }
  return {};
};

util.isEqual = function (objA, objB) {
  //相等
  if (objA === objB) return objA !== 0 || 1 / objA === 1 / objB;
  //空判断
  if (objA == null || objB == null) return objA === objB;
  //类型判断
  if (
    Object.prototype.toString.call(objA) !==
    Object.prototype.toString.call(objB)
  )
    return false;

  switch (Object.prototype.toString.call(objA)) {
    case '[object RegExp]':
    case '[object String]':
      //字符串转换比较
      return '' + objA === '' + objB;
    case '[object Number]':
      //数字转换比较,判断是否为NaN
      if (+objA !== +objA) {
        return +objB !== +objB;
      }

      return +objA === 0 ? 1 / +objA === 1 / objB : +objA === +objB;
    case '[object Date]':
    case '[object Boolean]':
      return +objA === +objB;
    case '[object Array]':
      //判断数组
      for (let i = 0; i < objA.length; i++) {
        if (!util.isEqual(objA[i], objB[i])) return false;
      }
      return true;
    case '[object Object]':
      //判断对象
      let keys = Object.keys(objA);
      for (let i = 0; i < keys.length; i++) {
        if (!util.isEqual(objA[keys[i]], objB[keys[i]])) return false;
      }

      keys = Object.keys(objB);
      for (let i = 0; i < keys.length; i++) {
        if (!util.isEqual(objA[keys[i]], objB[keys[i]])) return false;
      }

      return true;
    default:
      return false;
  }
};

util.datIncrease = function (days) {
  const checkDay = Number(days);
  let formattedDate = '';

  if (checkDay > 0) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + checkDay);

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
};

util.checkImgType = (img) => {
  return /.png|.jpg|.jpeg/.test(img);
};

util.allowImgTypeConfig = ['png', 'jpg', 'jpeg'];

export default util;
