import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';

Vue.use(Router);

const login = (r) =>
  require.ensure([], () => r(require('@/page/login2')), 'login');
const manage = (r) =>
  require.ensure([], () => r(require('@/page/manage')), 'manage');
const home = (r) => require.ensure([], () => r(require('@/page/home')), 'home');
const pageCompany = (r) =>
  require.ensure([], () => r(require('@/page/pageCompany')), 'pageCompany');
const pageSoftware = (r) =>
  require.ensure([], () => r(require('@/page/pageSoftware')), 'pageSoftware');

const pageCustomer = (r) =>
  require.ensure([], () => r(require('@/page/pageCustomer')), 'pageCustomer');
const pageClient = (r) =>
  require.ensure([], () => r(require('@/page/pageClient')), 'pageClient');
const pageUsers = (r) =>
  require.ensure([], () => r(require('@/page/pageUsers')), 'pageUsers');

const pageTemplate = (r) =>
  require.ensure([], () => r(require('@/page/pageTemplate')), 'pageTemplate');
const pageAttach = (r) =>
  require.ensure([], () => r(require('@/page/pageAttach')), 'pageAttach');
const pageBalance = (r) =>
  require.ensure([], () => r(require('@/page/pageBalance')), 'pageBalance');
const pageManager = (r) =>
  require.ensure([], () => r(require('@/page/pageManager')), 'pageManager');

const pageLaw = (r) =>
  require.ensure([], () => r(require('@/page/pageLaw')), 'pageLaw');
const pageRichText = (r) =>
  require.ensure([], () => r(require('@/page/pageRichText')), 'pageRichText');

const pageMaintenanceConfirm = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageMaintenanceConfirm')),
    'pageMaintenanceConfirm'
  );
const pageMaintenanceCheck = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageMaintenanceCheck')),
    'pageMaintenanceCheck'
  );

const pageMaintenanceRequest = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageMaintenanceRequest')),
    'pageMaintenanceRequest'
  );

const pageMaintenance = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageMaintenance')),
    'pageMaintenance'
  );
const countSetup = (r) =>
  require.ensure([], () => r(require('@/page/countSetup')), 'countSetup');

const countOpen = (r) =>
  require.ensure([], () => r(require('@/page/countOpen')), 'countOpen');
const countChineseType = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/countChineseType')),
    'countChineseType'
  );
const countCopyright = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/countCopyright')),
    'countCopyright'
  );
const countFeeType = (r) =>
  require.ensure([], () => r(require('@/page/countFeeType')), 'countFeeType');
const countSoftware = (r) =>
  require.ensure([], () => r(require('@/page/countSoftware')), 'countSoftware');

const pageRole = (r) =>
  require.ensure([], () => r(require('@/page/pageRole')), 'pageRole');
const pageAction = (r) =>
  require.ensure([], () => r(require('@/page/pageAction')), 'pageAction');
const editRoleAction = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/editRoleAction')),
    'editRoleAction'
  );
const treeMetaData = (r) =>
  require.ensure([], () => r(require('@/page/treeMetaData')), 'treeMetaData');

const pageFree = (r) =>
  require.ensure([], () => r(require('@/page/pageFree')), 'pageFree');
const pageDepartment = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageDepartment')),
    'pageDepartment'
  );
const pagePlan = (r) =>
  require.ensure([], () => r(require('@/page/pagePlan')), 'pagePlan');
const pageRequest = (r) =>
  require.ensure([], () => r(require('@/page/pageRequest')), 'pageRequest');

const pageMessage = (r) =>
  require.ensure([], () => r(require('@/page/pageMessage')), 'pageMessage');
const pageNewMessage = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageNewMessage')),
    'pageNewMessage'
  );

const pageMatch = (r) =>
  require.ensure([], () => r(require('@/page/pageMatch')), 'pageMatch');
const pageMatchScheduleClass = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageMatchScheduleClass')),
    'pageMatchScheduleClass'
  );
const pageMatchSchedule = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageMatchSchedule')),
    'pageMatchSchedule'
  );
const pageTeam = (r) =>
  require.ensure([], () => r(require('@/page/pageTeam')), 'pageTeam');
const nicename = (r) =>
  require.ensure([], () => r(require('@/page/nicename')), 'nicename');
const blacklist = (r) =>
  require.ensure([], () => r(require('@/page/blacklist')), 'blacklist');
const pageLiveReview = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageLiveReview')),
    'pageLiveReview'
  );
const pageHotMatch = (r) =>
  require.ensure([], () => r(require('@/page/pageHotMatch')), 'pageHotMatch');
const pageUsersLiveHis = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageUsersLiveHis')),
    'pageUsersLiveHis'
  );

const pageRooms = (r) =>
  require.ensure([], () => r(require('@/page/pageRooms')), 'pageRooms');
const pageGroups = (r) =>
  require.ensure([], () => r(require('@/page/pageGroups')), 'pageGroups');
const pageFriends = (r) =>
  require.ensure([], () => r(require('@/page/pageFriends')), 'pageFriends');
const pageFeedback = (r) =>
  require.ensure([], () => r(require('@/page/pageFeedback')), 'pageFeedback');
const pageSwiper = (r) =>
  require.ensure([], () => r(require('@/page/pageSwiper')), 'pageSwiper');
const pageLevel = (r) =>
  require.ensure([], () => r(require('@/page/pageLevel')), 'pageLevel');
const pageRights = (r) =>
  require.ensure([], () => r(require('@/page/pageRights')), 'pageRights');
const pageMetaData = (r) =>
  require.ensure([], () => r(require('@/page/pageMetaData')), 'pageMetaData');
const pageAppVer = (r) =>
  require.ensure([], () => r(require('@/page/pageAppVer')), 'pageAppVer');
const pageArticle = (r) =>
  require.ensure([], () => r(require('@/page/pageArticle')), 'pageArticle');
const pageStanding = (r) =>
  require.ensure([], () => r(require('@/page/pageStanding')), 'pageStanding');
const pagePlayer = (r) =>
  require.ensure([], () => r(require('@/page/pagePlayer')), 'pagePlayer');
const pageRecommend = (r) =>
  require.ensure([], () => r(require('@/page/pageRecommend')), 'pageRecommend');
const pageIpCount = (r) =>
  require.ensure([], () => r(require('@/page/pageIpCount')), 'pageIpCount');
const pageMatchCity = (r) =>
  require.ensure([], () => r(require('@/page/pageMatchCity')), 'pageMatchCity');
const pageWhiteIp = (r) =>
  require.ensure([], () => r(require('@/page/pageWhiteIp')), 'pageWhiteIp');
const pageGilt = (r) =>
  require.ensure([], () => r(require('@/page/pageGilt')), 'pageGilt');
const my = (r) => require.ensure([], () => r(require('@/page/my')), 'my');
const pageFaq = (r) =>
  require.ensure([], () => r(require('@/page/pageFaq')), 'pageFaq');
const pageblock = (r) =>
  require.ensure([], () => r(require('@/page/pageBlock')), 'pageBlock');

const pageBannerPcBaseMap = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageBannerPcBaseMap')),
    'pageBannerPcBaseMap'
  );
const pageBannerManage = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageBannerManage')),
    'pageBannerManage'
  );
const pageCompetitionRecommde = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageCompetitionRecommde')),
    'pageCompetitionRecommde'
  );
const pageAnchorRecommed = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageAnchorRecommed')),
    'pageAnchorRecommed'
  );
const pageLiveRecommed = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageLiveRecommed')),
    'pageLiveRecommed'
  );
const pageAnchorCover = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageAnchorCover')),
    'pageAnchorCover'
  );
const pageLiveCover = (r) =>
  require.ensure([], () => r(require('@/page/pageLiveCover')), 'pageLiveCover');
const pageDomainChannel = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageDomainChannel')),
    'pageDomainChannel'
  );
const pageUserQuery = (r) =>
  require.ensure([], () => r(require('@/page/pageUserQuery')), 'pageUserQuery');
const pageBrand = (r) =>
  require.ensure([], () => r(require('@/page/pageBrand')), 'pageBrand');
const pageBrandChannel = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageBrandChannel')),
    'pageBrandChannel'
  );
const pageStreamProvider = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageStreamProvider')),
    'pageStreamProvider'
  );
const pageChannel = (r) =>
  require.ensure([], () => r(require('@/page/pageChannel')), 'pageChannel');
const pageChannelManage = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageChannelManage')),
    'pageChannelManage'
  );
const pageClickLog = (r) =>
  require.ensure([], () => r(require('@/page/pageClickLog')), 'pageClickLog');
const pageChannelLog = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageChannelLog')),
    'pageChannelLog'
  );
const pageLiveRoomData = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageLiveRoomData')),
    'pageLiveRoomData'
  );
const pageLiveDailyData = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageLiveDailyData')),
    'pageLiveDailyData'
  );
const pageChannelLogo = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageChannelLogo')),
    'pageChannelLogo'
  );
const pageUpAd = (r) =>
  require.ensure([], () => r(require('@/page/pageUpAd')), 'pageUpAd');
const pageToutiao = (r) =>
  require.ensure([], () => r(require('@/page/pageToutiao')), 'pageToutiao');
const pageDraw = (r) =>
  require.ensure([], () => r(require('@/page/pageDraw')), 'pageDraw');
const pageEditAward = (r) =>
  require.ensure([], () => r(require('@/page/pageEditAward')), 'pageEditAward');

const pageBrandStreamProvider = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageBrandStreamProvider')),
    'pageBrandStreamProvider'
  );
const pageBatchMsg = (r) =>
  require.ensure([], () => r(require('@/page/pageBatchMsg')), 'pageBatchMsg');

const pageBatchMsgHistory = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageBatchMsgHistory')),
    'pageBatchMsgHistory'
  );
const pageBatchMsgReview = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageBatchMsgReview')),
    'pageBatchMsgReview'
  );
const searchIpAndBlock = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/searchIpAndBlock.vue')),
    'searchIpAndBlock'
  );
const pageUserBlockIp = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageUserBlockIp.vue')),
    'pageUserBlockIp'
  );
const pageDomainStats = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageDomainStats')),
    'pageDomainStats'
  );
const pageUserOnlineRecord = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageUserOnlineRecord.vue')),
    'pageUserOnlineRecord'
  );
const pageAd = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageAd.vue')),
    'pageAd'
);

const pageAdStreamer = (r) =>
  require.ensure(
    [],
    () => r(require('@/page/pageAdStreamer.vue')),
    'pageAdStreamer'
  );

const routes = [
  { path: '/', component: login },
  {
    path: '/manage',
    component: manage,
    children: [
      {
        path: '',
        component: home,
        name: '概览'
      },
      {
        path: '/pageMatch',
        component: pageMatch,
        name: '赛事管理'
      },
      {
        path: '/pageIpCount',
        component: pageIpCount,
        name: '每日IP统计'
      },
      {
        path: '/nicename',
        component: nicename,
        name: '美称管理'
      },
      {
        path: '/blacklist',
        component: blacklist,
        name: '敏感词管理'
      },
      {
        path: '/pageLiveReview',
        component: pageLiveReview,
        name: '直播审核'
      },
      {
        path: '/pageHotMatch',
        component: pageHotMatch,
        name: '直播中频道'
      },
      {
        path: '/pageUsersLiveHis',
        component: pageUsersLiveHis,
        name: '历史直播'
      },
      {
        path: '/pageTeam',
        component: pageTeam,
        name: '队伍管理'
      },
      {
        path: '/pageMatchScheduleClass',
        component: pageMatchScheduleClass,
        name: '赛程管理分类'
      },
      {
        path: '/pageMatchSchedule',
        component: pageMatchSchedule,
        name: '赛程管理'
      },
      {
        path: '/pageDepartment',
        component: pageDepartment,
        name: '分组管理'
      },
      {
        path: '/pageMessage',
        component: pageMessage,
        name: '消息管理'
      },
      {
        path: '/pageNewMessage',
        component: pageNewMessage,
        name: '新版消息管理'
      },
      {
        path: '/pageUsers',
        component: pageUsers,
        name: '用户管理'
      },
      {
        path: '/pageAttach',
        component: pageAttach,
        name: '文档管理'
      },
      {
        path: '/pageTemplate',
        component: pageTemplate,
        name: '模板管理1'
      },
      {
        path: '/pageCustomer',
        component: pageCustomer,
        name: '账号管理'
      },
      {
        path: '/pageRole',
        component: pageRole,
        name: '角色管理'
      },
      {
        path: '/pageAction',
        component: pageAction,
        name: '模块管理'
      },
      {
        path: '/editRoleAction',
        component: editRoleAction,
        name: '权限设置'
      },
      {
        path: '/treeMetaData',
        component: treeMetaData,
        name: '元数据管理'
      },
      {
        path: '/my',
        component: my,
        name: '个人信息'
      },

      {
        path: '/pageRooms',
        component: pageRooms,
        name: '直播间列表'
      },
      {
        path: '/pageGroups',
        component: pageGroups,
        name: '群组列表'
      },
      {
        path: '/pageFriends',
        component: pageFriends,
        name: '朋友列表'
      },
      {
        path: '/pageFeedback',
        component: pageFeedback,
        name: '客户反馈'
      },
      {
        path: '/pageLevel',
        component: pageLevel,
        name: '用户级别'
      },
      {
        path: '/pageRights',
        component: pageRights,
        name: '用户特权'
      },
      {
        path: '/pageMetaData',
        component: pageMetaData,
        name: '系统参数设置'
      },

      {
        path: '/pageMetaData',
        component: pageMetaData,
        name: '默认公告'
      },
      {
        path: '/pageAppVer',
        component: pageAppVer,
        name: 'App版本管理'
      },
      {
        path: '/pageArticle',
        component: pageArticle,
        name: '新闻管理'
      },
      {
        path: '/pageStanding',
        component: pageStanding,
        name: '积分榜'
      },
      {
        path: '/pagePlayer',
        component: pagePlayer,
        name: '射手榜'
      },
      {
        path: '/pageRecommend',
        component: pageRecommend,
        name: '专家推荐'
      },
      {
        path: '/pageSwiper',
        component: pageSwiper,
        name: '轮播图管理'
      },
      {
        path: '/pageMatchCity',
        component: pageMatchCity,
        name: '屏蔽区域'
      },
      {
        path: '/pageWhiteIp',
        component: pageWhiteIp,
        name: 'IP白名单'
      },
      {
        path: '/pageGilt',
        component: pageGilt,
        name: '礼物管理'
      },
      {
        path: '/pageblock',
        component: pageblock,
        name: '屏蔽设备'
      },
      {
        path: '/pageBannerPcBaseMap',
        component: pageBannerPcBaseMap,
        name: 'PC端banner底图配置'
      },
      {
        path: '/pageBannerManage',
        component: pageBannerManage,
        name: '首页Banner管理'
      },
      {
        path: '/pageCompetitionRecommde',
        component: pageCompetitionRecommde,
        name: '首页比赛推荐'
      },
      {
        path: '/pageAnchorRecommed',
        component: pageAnchorRecommed,
        name: '首页主播推荐'
      },
      {
        path: '/pageLiveRecommed',
        component: pageLiveRecommed,
        name: '首页直播推荐'
      },
      {
        path: '/pageFaq',
        component: pageFaq,
        name: '一键咨询内容管理'
      },
      {
        path: '/pageAnchorCover',
        component: pageAnchorCover,
        name: '主播个人封面'
      },
      {
        path: '/pageLiveCover',
        component: pageLiveCover,
        name: '直播封面管理'
      },
      {
        path: '/pageDomainChannel',
        component: pageDomainChannel,
        name: '域名渠道管理'
      },
      {
        path: '/pageUserQuery',
        component: pageUserQuery,
        name: '用户查询'
      },
      {
        path: '/pageBrand',
        component: pageBrand,
        name: '子平台管理'
      },
      {
        path: '/pageBrandChannel',
        component: pageBrandChannel,
        name: '子平台渠道管理'
      },
      {
        path: '/pageStreamProvider',
        component: pageStreamProvider,
        name: '推流商管理'
      },
      {
        path: '/pageChannel',
        component: pageChannel,
        name: '生成渠道包'
      },
      {
        path: '/pageChannelManage',
        component: pageChannelManage,
        name: '渠道号管理'
      },
      {
        path: '/pageClickLog',
        component: pageClickLog,
        name: '外链点击日志'
      },
      {
        path: '/pageChannelLog',
        component: pageChannelLog,
        name: '渠道日志'
      },
      {
        path: '/pageLiveRoomData',
        component: pageLiveRoomData,
        name: '直播间数据'
      },
      {
        path: '/pageLiveDailyData',
        component: pageLiveDailyData,
        name: '每日直播数据'
      },
      {
        path: '/pageChannelLogo',
        component: pageChannelLogo,
        name: '渠道logo管理'
      },
      {
        path: '/pageUpAd',
        component: pageUpAd,
        name: '模板管理'
      },
      {
        path: '/pageToutiao',
        component: pageToutiao,
        name: '头条管理'
      },
      {
        path: '/pageDraw',
        component: pageDraw,
        name: '抽奖记录查询'
      },
      {
        path: '/pageEditAward',
        component: pageEditAward,
        name: '抽奖活动管理'
      },
      {
        path: '/pageBrandStreamProvider',
        component: pageBrandStreamProvider,
        name: '子平台推流管理'
      },
      {
        path: '/pageBatchMsg',
        component: pageBatchMsg,
        name: '批量发送申请'
      },
      {
        path: '/pageBatchMsgHistory',
        component: pageBatchMsgHistory,
        name: '批量发送记录'
      },
      {
        path: '/pageBatchMsgReview',
        component: pageBatchMsgReview,
        name: '批量发送审核'
      },
      {
        path: '/searchIpAndBlock',
        component: searchIpAndBlock,
        name: 'IP查询及封锁'
      },
      {
        path: '/pageUserBlockIp',
        component: pageUserBlockIp,
        name: '已封锁IP'
      },
      {
        path: '/pageDomainStats',
        component: pageDomainStats,
        name: '域名移动统计管理'
      },
      {
        path: '/pageUserOnlineRecord',
        component: pageUserOnlineRecord,
        name: '用户上线记录'
      },
      {
        path: '/pageAdStreamer',
        component: pageAdStreamer,
        name: '主播广告配置'
      },
      {
        path: '/pageAd',
        component: pageAd,
        name: '广告模板配置'
      },
    ]
  }
];

const route = new Router({
  routes,
  strict: process.env.NODE_ENV !== 'production'
});

route.beforeEach((to, from, next) => {
  if (typeof document?.head?.querySelector === 'function') {
    const title = document.head.querySelector('title');
    if (typeof to.name === 'string' && to.name !== '') {
      title.innerHTML = `直播管理平台-${to.name}`;
    } else {
      title.innerHTML = '直播管理平台';
    }
  }
  next();
});

export default route;
