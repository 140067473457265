<template>
  <div class="edit-link">
    <div class="edit-link-top">
      <div class="edit-pic">
        <ag-uploader
          v-model="val.noticeThumb"
          :isAsset="true"
          :allowImgType="allowImgType"
        >
          <div style="width: 100px; height: 100px" v-if="!val.noticeThumb">
            添加图片
          </div>
          <img
            style="width: 100px; height: 100px"
            class="sale-pic-img"
            v-else
            :src="val.noticeThumb | url"
          />
        </ag-uploader>
      </div>
      <div class="edit-con">
        <!-- <input
          class="title-input"
          maxlength="100"
          v-model.trim="val.noticeTitle"
          placeholder="请输入标题"
        /> -->
        <!-- <textarea
          maxlength="300"
          v-model.trim="val.noticeContent"
          placeholder="请输入内容"
        ></textarea> -->
        <el-input
          type="text"
          maxlength="100"
          show-word-limit
          clearable
          v-model.trim="val.noticeTitle"
          placeholder="请输入标题"
        ></el-input>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          maxlength="300"
          show-word-limit
          clearable
          v-model.trim="val.noticeContent"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>
    <div class="edit-link-input">
      <!-- <input
        class="link-input"
        v-model.trim="val.noticeUrl"
        placeholder="请输入链接"
        maxlength="100"
      /> -->
      <el-input
          type="text"
          maxlength="100"
          show-word-limit
          clearable
          v-model.trim="val.noticeUrl"
          placeholder="请输入链接"
        ></el-input>
    </div>
    <!-- <div class="edit-link-send">
             <el-button type="primary" @click="sendLink">设置直播间公告</el-button>

    	</div> -->
  </div>
</template>

<script>
import api from '../api/api.js';
import util from '@/config/util';

export default {
  props: ['value', 'showGif'],
  mounted() {
    this.val = this.value;
  },
  data() {
    return {
      val: {
        noticeTitle: '',
        noticeContent: '',
        noticeThumb: '',
        noticeUrl: ''
      },
      allowImgType: this.showGif
        ? [...util.allowImgTypeConfig, 'gif']
        : util.allowImgTypeConfig
    };
  },
  created() {},
  watch: {
    value(n, o) {
      this.val = n;
    }
  },
  computed: {},
  destroyed() {},
  methods: {}
};
</script>

<style lang="less" scoped="scoped">
@import url('../style/message.less');
</style>
