<template>
  <div class="google-wrapper">
    <el-dialog
      :title="'绑定Google验证'"
      width="700px"
      :visible.sync="showBangding"
      @close="closeDialog"
    >
      <div class="dialog-content">
        <i
          type="primary"
          class="refresh-icon el-icon-refresh-left"
          @click="refresh"
        ></i>
        <div class="dialog-box">
          <div class="down-qr">
            <p class="down-text">第一步：下载Google Authenticator</p>
            <div class="down-contetn">
              <div class="qr-box">
                <div id="qrcodeA" ref="qrcodeA"></div>
                <div class="qr-text">
                  <p>Download on the</p>
                  <p class="last-text">App Store</p>
                </div>
              </div>
              <div class="qr-box">
                <div id="qrcodeB" ref="qrcodeB"></div>
                <div class="qr-text">
                  <p>Download on the</p>
                  <p class="last-text">Android</p>
                </div>
              </div>
            </div>
          </div>
          <div class="qr-code">
            <p class="down-text">第二步：扫描QR验证码</p>
            <div id="qrcode" class="qrcode" ref="qrcode"></div>
          </div>
        </div>
        <el-form class="edit-form" size="mini">
          <el-form-item label="第三步：绑定谷歌验证器">
            <el-input v-model="form.code" style="width: 300px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editPaswordClick">确认绑定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api';
import util from '@/config/util';
import QRCode from 'qrcodejs2';
export default {
  props: {
    isBangding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { key: '', code: '' },
      url: '',
      loading: false,
      showBangding: false
    };
  },
  watch: {
    isBangding(n) {
      this.showBangding = n;
      if (n) {
        this.getInfoCodeGoogle();
        this.$nextTick(() => {
          this.bindQRCode();
        });
      }
    }
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    bindQRCode() {
      let qrcodeA = document.getElementById('qrcodeA');
      let qrcodeB = document.getElementById('qrcodeB');
      let ca = qrcodeA.getElementsByTagName('canvas');
      let ia = qrcodeA.getElementsByTagName('img');
      let cb = qrcodeB.getElementsByTagName('canvas');
      let ib = qrcodeB.getElementsByTagName('img');
      if (ca && ca.length && ia && ia.length) {
        qrcodeA.removeChild(ca[0]);
        qrcodeA.removeChild(ia[0]);
      }
      if (cb && cb.length && ib && ib.length) {
        qrcodeB.removeChild(cb[0]);
        qrcodeB.removeChild(ib[0]);
      }

      let urlA =
        'https://apps.apple.com/cn/app/google-authenticator/id388497605';
      let urlB =
        'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=zh&gl=US';
      new QRCode(qrcodeA, {
        width: 130,
        height: 130,
        text: urlA,
        render: 'canvas'
      });
      new QRCode(qrcodeB, {
        width: 130,
        height: 130,
        text: urlB,
        render: 'canvas'
      });
    },
    refresh() {
      this.getInfoCodeGoogle();
    },
    getInfoCodeGoogle() {
      api
        .infoCodeGoogle()
        .then((resp) => {
          if (resp && resp.data) {
            this.form.key = resp.data.key;
            this.url = resp.data.url;
            this.qRCode();
          }
        })
        .catch((err) => {});
    },
    qRCode() {
      let qrcode = document.getElementById('qrcode');
      let c = qrcode.getElementsByTagName('canvas');
      let i = qrcode.getElementsByTagName('img');
      if (c && c.length && i && i.length) {
        qrcode.removeChild(c[0]);
        qrcode.removeChild(i[0]);
      }
      new QRCode(qrcode, {
        width: 150,
        height: 150,
        text: this.url,
        render: 'canvas'
      });
    },
    editPaswordClick() {
      if (this.loading) {
        return;
      }
      if (!this.form.code) {
        util.message('请输入验证码');
        return;
      }
      api
        .editCodeGoogle(this.form)
        .then((resp) => {
          this.loading = false;
          util.message(resp.message);
          if (this.$store.state.infoLogin && this.$store.state.infoLogin.id) {
            this.$store.state.infoLogin.hasCodeGoogle = true;
          }

          this.$emit('close');
        })
        .catch((err) => {
          this.loading = false;
          util.message(err.message);
        });
    }
  }
};
</script>
<style lang="less" scoped>
.dialog-content {
  flex: 1;
  padding-bottom: 30px;
  position: relative;
}
.refresh-icon {
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 15px;
  cursor: pointer;
}
.dialog-box {
  display: -ms-flexbox;
  display: flex;
}
.down-qr {
  -ms-flex: 1;
  flex: 1;
  padding-bottom: 30px;
}
.down-qr p {
  line-height: 40px;
}

.down-text {
  line-height: 40px;
  font-size: 17px;
  font-weight: 700;
}
.down-contetn {
  display: -ms-flexbox;
  display: flex;
}
.qr-box {
  width: 130px;
  -ms-flex: 1;
  flex: 1;
  margin-right: 20px;
}
.qr-text {
  width: 130px;
  text-align: center;
  background-color: #000;
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px 0;
}
.qr-text p {
  line-height: 20px;
  color: #fff;
  font-size: 12px;
}
.qr-text .last-text {
  font-size: 16px;
  font-weight: 700;
}
.qr-code {
  -ms-flex: 1;
  flex: 1;
}
.down-text {
  line-height: 40px;
  font-size: 17px;
  font-weight: 700;
}
.qrcode {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-top: 30px;
}
</style>
