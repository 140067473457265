import api from '@/api/api';
import util from '@/config/util';
import Vue from 'vue';

const actions = {};

actions.api = function (action) {
  let store = this;
  let state = store.state;
  let a = state[action];

  let form = a.form;

  return api[action](form)
    .then((resp) => {
      //Vue.set(a, data, resp.data)

      a.data = resp.data;

      return new Promise((resolve, reject) => {
        if (resp.success) {
          resolve(resp);
        } else {
          reject(resp);
        }
      });
    })
    .catch((res) => {
      return new Promise((resolve, reject) => {
        reject(res);
      });
    });
};

for (let i in api) {
  actions[i + 'Click'] = () => {};
}

actions.dateFormatter = function (row, col) {
  let date = row[col.property];

  if (!date) {
    return '';
  }
  if (date instanceof Date) {
    return util.format(date);
  }
  if (date.length && date.length > 10) {
    return date.substr(0, 10);
  }
  return date;
};

export default actions;
