import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import api from '@/api/api';
import Message from './storeMessage';
import copyRoom from './copyRoom';
import draw from './draw';
import user from './user';
import upList from './upList';
import readmsg from './readmsg';

import systemModule from '@/store/module/system';

Vue.use(Vuex);

const state = {};
const getters = {};
state.api = {};
state.auth = {};
for (let i in api) {
  state.api[i] = { data: '', form: { size: 15 }, visible: false };
}
const store = {
  state,
  actions,
  mutations,
  getters,
  modules: {
    system: systemModule,
  }
};
user(store);
readmsg(store);
draw(store);
Message(store);
copyRoom(store);
upList(store);
export default new Vuex.Store(store);
