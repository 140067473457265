var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"author"},[(_vm.item)?_c('div',{staticClass:"author-img",class:{
      'author-live-img': _vm.tab == 1,
      'author-group-img': _vm.tab == 2,
      'author-friend-img': _vm.tab == 3,
      'assist-friend-img': _vm.tab == 4
    }},[(_vm.item.joinMap && _vm.item.joinMap.u && _vm.item.joinMap.u.avatar)?_c('img',{staticClass:"room-name-pic",attrs:{"src":_vm._f("url")(_vm.item.joinMap && _vm.item.joinMap.u && _vm.item.joinMap.u.avatar)}}):_c('span',{staticClass:"author-text"},[_vm._v(_vm._s(_vm.item.name && _vm.item.name.trim().charAt(0)))]),(_vm.item.mute == 1 && _vm.tabActive == 3)?_c('span',{staticClass:"iconfont icon-jinyan author-icon"}):_vm._e(),(
        _vm.item.joinMap &&
        _vm.item.joinMap.u &&
        _vm.item.joinMap.u.cancel == 1 &&
        _vm.tabActive == 4
      )?_c('span',{staticClass:"iconfont icon-dongjie author-icon author-dongjie"}):_vm._e()]):_vm._e(),(_vm.isDraw)?_c('span',{staticClass:"iconfont icon-draw"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }