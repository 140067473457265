<template>
  <div class="ag-emotion">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ag-emotion'
};
</script>
<style scoped>
.ag-emotion {
  display: inline-block;
  font-size: 20px;
}
.ag-emotion:hover {
  background-color: #e8e8e8;
}
</style>
