<template>
  <el-dialog title="创建群组" width="800px" :visible="visible" @close="close">
    <div class="qun">
      <el-form :inline="true">
        <el-form-item label="创建新群">
          <el-switch v-model="newgroup"></el-switch>
        </el-form-item>
        <el-form-item label="加入群组" v-if="!newgroup">
          <ag-select
            :data="listGroup"
            name="groupId"
            v-model="form.groupId"
          ></ag-select>
        </el-form-item>
        <el-form-item label="群组名称" v-else>
          <el-input
            clearable
            class="qun-name"
            placeholder="输入群组名称"
            v-model="form.name"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="user-list">
        <div class="qun1">
          <span class="qun-title">请选择用户</span>
          <el-input
            class="qun-search"
            placeholder="搜索"
            suffix-icon="el-icon-search"
            size="mini"
            v-model="search"
          ></el-input>
          <div
            v-for="item in list2"
            :key="item.id"
            v-show="item.disabled"
            @click="add(item)"
            class="qunyonghu"
          >
            <el-tag type="info" title="点击把该用户加入群组">{{
              item.userNicename
            }}</el-tag>
          </div>
        </div>
        <div class="qun2">
          <span class="qun-title">已选用户</span>
          <div
            v-for="item in list"
            :key="item.id"
            v-show="!item.disabled"
            @click="remove(item)"
            class="qunyonghu"
          >
            <el-tag type="success" title="点击删除该成员">{{
              item.userNicename
            }}</el-tag>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="editGroup">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/api';
import util from '@/config/util';
import headTop from '@/components/headTop';
import { mapState, mapActions } from 'vuex';
import config from '@/config/default';
import { uploadUrl, downloadUrl } from '@/config/env';
import vars from '@/config/vars';
import word from '@/config/word';
import Emotion from '@/components/emotion';
import uploader from '@/components/uploader';

export default {
  data() {
    return {
      form: { groupId: undefined, name: '', userId: [] },
      search: '',
      newgroup: true,
      listGroup: []
    };
  },
  props: {
    groupId: {
      default: undefined
    },
    name: {
      default: ''
    },
    list: {
      default: ''
    },
    visible: {
      default: true
    }
  },
  created() {},
  computed: {
    list2() {
      if (!this.search) {
        return this.list;
      }
      return this.list.filter((r) => {
        return r.userNicename.indexOf(this.search) > -1;
      });
    }
  },
  mounted() {
    this.form.groupId = this.groupId;
    this.form.name = this.name;

    api.listGroup().then((resp) => {
      resp.data.forEach((r) => {
        r.name = r.joinMap.g.name;
      });
      this.listGroup = resp.data;
    });
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    remove(item) {
      this.$set(item, 'disabled', true);
    },
    add(item) {
      this.$set(item, 'disabled', false);
    },
    async editGroup() {
      if (!this.newgroup) {
        this.form.groupId = undefined;
      }
      if (!this.form.groupId && !this.form.name) {
        util.message('请输入群组名称');
        return;
      }
      let userId = [];
      this.list.forEach((r) => {
        if (!r.disabled) {
          userId.push(r.id);
        }
      });
      if (userId.length == 0) {
        util.message('请选择用户');
        return;
      }

      this.form.userId = userId;
      try {
        const resp = await api.editGroup(this.form);
        this.visible = false;
        util.message(resp.message);
        this.$emit('input', resp.data);
        // this.$store.dispatch('messageListGroup'); // 取得群組清單
        const newGroupData = resp.data;

        // 邏輯拷貝於 src/store/storeMessage.js/actions.messageListGroup
        const g = newGroupData.joinMap?.g;
        newGroupData.name = g.name || newGroupData.groupName;
        const msg = util.json(newGroupData.lastMsg);
        if (msg?.title) {
          newGroupData.lastMsg = '' + msg.title;
        }
        if (msg?.message) {
          newGroupData.lastMsg = msg.message;
        }

        this.$store.commit('messageListGroup', [
          newGroupData,
          ...this.$store.state.message.group
        ]); // 更新群組清單
      } catch (error) {
        util.error(error.message);
      }
    }
  }
};
</script>

<style lang="less">
.user-list {
  height: 300px;
  display: flex;
}
.qun1 {
  flex: 1;
  padding: 10px;
  overflow: scroll;
}
.qun2 {
  flex: 1;
  overflow: scroll;
  padding: 10px;
}
.qunyonghu .el-tag {
  cursor: pointer;
}
</style>
