<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
/deep/.el-input__inner {
  border-radius: 6px !important;
}
.el-table:before {
  height: 0px !important;
}
.el-dialog .el-form {
  border-top: 0px !important;
}
.el-time-panel__btn {
  border: none;
  line-height: 20px !important;
  padding: 0 5px !important;
  margin: 0 5px !important;
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  font-size: 12px;
  color: #303133;
}
</style>
