<template>
  <div class="ag-video-player" ref="videoplay" @click="click">
    <!-- <view class="ag-video-main" ref="video"></view> -->
    <video
      :src="sourceUrls"
      ref="video"
      class="ag-video-main"
      style="width: 100%; height: 100%"
      webkit-playsinline
      controls
      playsinline
      preload
      autoplay
    ></video>
  </div>
</template>

<script>
let video = null;

import Hls from 'hls.js';
import agVideoControl from './agVideoControl.vue';

import util from '@/config/util.js';

export default {
  props: {
    sourceUrl: {
      type: String,
      default: ''
    }
  },
  components: {
    'ag-video-control': agVideoControl
  },
  data() {
    return {
      hls: null,
      sourceUrls: this.sourceUrl,
      isHideBar: false,
      isPlay: true,
      isFullscreen: false,
      ishigh: false,
      controlerShowTimer: '',
      timer: '',
      visible: false,
      popupContent: ''
    };
  },
  watch: {
    sourceUrl(n) {
      this.sourceUrls = n;
      if (n) {
        this.playVideo();
      }
    }
  },
  destroyed() {
    this.videoPause();
  },
  mounted() {
    if (this.sourceUrls) {
      this.playVideo();
    }
    let that = this;
    document.addEventListener('fullscreenchange', () => {
      let isFull = that.checkFullScreen();
      if (!isFull) {
        that.clearTimeout();
        this.$nextTick(() => {
          that.isFullscreen = false;
        });
      }
    });
  },
  created() {},
  methods: {
    volumeChange(v) {
      video.volume = v;
    },
    async onlogin(user) {
      if (user && user.userType < 3) {
        this.ishigh = false;
        let bootdownapp = this.$refs.bootdownapp;
        if (bootdownapp) {
          console.log('bootdownapp', bootdownapp);
          await bootdownapp.loadData();
        }
      }
    },
    onhigh(h) {
      this.ishigh = h;
    },
    checkFullScreen() {
      let isFull =
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement ||
        document.fullscreenElement;
      if (isFull == null || isFull == undefined) {
        isFull = false;
      }
      return isFull;
    },
    videoPause() {
      if (this.hls) {
        video.pause();
        this.hls.destroy();
        this.hls = null;
      }
    },
    playVideo() {
      let muted = true;
      let volume = false;
      video = this.$refs.video;
      if (Hls.isSupported()) {
        if (video) {
          video.muted = muted;
          video.volume = volume;
          console.log('支持hls播放', this.sourceUrls);
          this.hls = new Hls();
          this.hls.loadSource(this.sourceUrls);
          this.hls.attachMedia(video);
          this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
            video.play();
          });

          this.hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.log('网络故障了...');
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log('播放流断了....');
                  hls.recoverMediaError();
                  break;
                default:
                  video.pause();
                  hls.destroy();
                  break;
              }
            }
          });
        } else {
          util.message('视频播放失败，请刷新');
        }
      } else {
        util.message('不支持m3u8格式的视频');
      }
      this.changeBar();
    },
    click() {
      this.isHideBar = !this.isHideBar;
      this.clearTimeout();
    },
    play() {
      this.isPlay = true;
      video.play();
      this.clearTimeout();
      this.$emit('play');
    },
    pause() {
      this.isPlay = false;
      video.pause();
      this.clearTimeout();
      this.$emit('pause');
    },
    danmu() {},
    mute(type) {
      this.clearTimeout();
    },
    quality() {
      if (!this.ishigh) {
        this.visible = true;
        this.popupContent = '您正畅享高清画质';
        return;
      }
      let bootdownapp = this.$refs.bootdownapp;
      if (bootdownapp) {
        bootdownapp.onDownApp();
      }
      this.clearTimeout();
    },
    fullscreen() {
      let vp = this.$refs.videoplay.$el;
      if (vp) {
        // 全屏兼容代码
        if (vp.requestFullscreen) {
          vp.requestFullscreen();
        } else if (vp.webkitRequestFullscreen) {
          vp.webkitRequestFullscreen();
        } else if (vp.mozRequestFullScreen) {
          vp.mozRequestFullScreen();
        } else if (vp.msRequestFullscreen) {
          vp.msRequestFullscreen();
        } else if (vp.enterFullScreen) {
          vp.enterFullScreen();
        } else {
          let vi = video;
          if (vi.webkitEnterFullscreen) {
            vi.webkitEnterFullscreen();
          }
        }
      }
      this.isFullscreen = true;
      this.clearTimeout();
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      this.isFullscreen = false;
      this.clearTimeout();
    },
    changeMuted() {},
    changeBar() {
      this.controlerShowTimer = setTimeout(() => {
        this.isHideBar = true;
      }, 5000);
    },
    clearTimeout() {
      if (this.controlerShowTimer) {
        clearTimeout(this.controlerShowTimer);
      }
      this.changeBar();
    }
  }
};
</script>

<style lang="less" scoped>
.ag-video-player {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 999;
}
.ag-video-main {
  width: 100%;
  height: 100%;
}
.muted-text {
  position: absolute;
  bottom: 40px;
  z-index: 9999;
  color: #fff;
  right: 50px;
}
.video-bootdownapp {
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 99999;
}
.video-bootdownapp.isexitfullscreen .bootdownapp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
