let map = {
  'osengnls.24kzb.com': {
    title: '提审',
    api: 'osengnlsapi.24kzb.com/api',
    ws: 'im.24kzb.com/ws/',
    uploadUrl: 'osengnlsapi.24kzb.com/api?method=upload',
    downloadUrl: 'file.ryqclmzb31.cc/',
    brand: 'ts'
  },
  'ax.55223zb.xyz': {
    title: '播龙体育',
    api: 'x7eidtry.apzemtn0xd102.xyz/api',
    ws: 'im.mixiangchina.com/ws/',
    uploadUrl: 'x7eidtry.apzemtn0xd102.xyz/api?method=upload',
    downloadUrl: 'file.rcaiwangluo868.com/',
    brand: 'bl'
  },
  'admin.apszy13rsx119.xyz': {
    title: '五楼直播 UAT',
    api: 'api-admin.apszy13rsx119.xyz/api',
    ws: 'im.admin.apszy13rsx119.xyz/ws/',
    uploadUrl: 'api-admin.apszy13rsx119.xyz/api?method=upload',
    downloadUrl: 'file.apszy13rsx119.xyz/',
    brand: 'bl'
  },
  'admin.ap06cx2i4e106.xyz': {
    title: '五楼直播',
    api: 'api-admin.ap06cx2i4e106.xyz/api',
    ws: 'im.mixiangchina.com/ws/',
    uploadUrl: 'api-admin.ap06cx2i4e106.xyz/api?method=upload',
    downloadUrl: 'file.rcaiwangluo868.com/',
    brand: 'bl'
  }
};

let h = window.location.host;
console.log('location.host', h);

let m = map[h];
if (!m) {
  m = map['ax.55223zb.xyz'];
}

let brand = m.brand;
let baseUrl = `https://${m.api}`;
let websocketUrl = `wss://${m.ws}`;
let uploadUrl = `https://${m.uploadUrl}`;
let downloadUrl = `https://${m.downloadUrl}`;

let routerMode = 'hash';
let version = '2.11.5';
let verIndex = '20110005';
let channel = 'manage';
let timeout = 30000;

// console.log(`baseUrl: ${baseUrl}`);
// console.log(`websocketUrl: ${websocketUrl}`);
// console.log(`uploadUrl: ${uploadUrl}`);
// console.log(`downloadUrl: ${downloadUrl}`);

let iszbFriend = brand == 'h' ? false : true;
if (process.env.NODE_ENV == 'development') {
  timeout = 120000;
}

export {
  baseUrl,
  routerMode,
  websocketUrl,
  uploadUrl,
  downloadUrl,
  version,
  brand,
  verIndex,
  channel,
  timeout,
  iszbFriend
};
