import * as env from '@/config/env.js';

export default {
  namespaced: true,
  state: () => ({
    listMenu: [],
    meta: {},
    treeCourse: []
  }),
  mutations: {
    UPDATE_LIST_MENU(state, payload) {
      state.listMenu = payload || [];
    },
    UPDATE_META(state, payload) {
      state.meta = payload || {};
    },
    UPDATE_TREE_COURSE(state, payload) {
      state.treeCourse = payload || [];
    },
  },
  // actions: {
  // },
  getters: {
    env() {
      return { ...env };
    },
  },
};
