import api from '@/api/api';
import util from '@/config/util';
import Vue from 'vue';

const state = {};
const actions = {};
const mutations = {};
const getters = {};
state.listUpA = [];
state.listPlatform = [];

mutations.listUpA = function (state, data) {
  state.listUpA = data;
};

actions.listUpA = function (context, data) {
  api.listUpA().then((resp) => {
    if (!resp.data || !resp.data) {
      return;
    }
    context.commit('listUpA', resp.data);
  });
};

actions.listUpA = function (context, data) {
  api.listUpA().then((resp) => {
    if (!resp.data || !resp.data) {
      return;
    }
    context.commit('listUpA', resp.data);
  });
};

function user(store) {
  Object.assign(store.state, state);
  Object.assign(store.actions, actions);
  Object.assign(store.mutations, mutations);
  Object.assign(store.getters, getters);
}

export default user;
