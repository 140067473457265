<template>
  <div class="ag-video-control">
    <view v-show="isMute" @click.stop="notMute" class="video-mute">
      <view class="text">取消靜音模式</view>
      <view class="triangle"></view>
    </view>
    <div class="ag-video-content" :style="{ display: isDisplay }">
      <div class="ag-video-start">
        <span
          v-if="isPlays"
          @click.stop="clickPause"
          class="iconfont icon-pause ag-play-icon"
        ></span>
        <span
          v-else
          @click.stop="clickStart"
          class="iconfont icon-play ag-pause-icon"
        ></span>
      </div>
      <div class="ag-video-bar">
        <div class="bar-lf">
          <div class="lf-line"></div>
          <span>{{ qualityText }}直播中</span>
          <div class="danmu-item" v-if="openDanmu">
            <span
              v-if="isDanmu"
              @click.stop="clickDanmu"
              class="iconfont icon-chat-open danmu-icon"
            ></span>
            <span
              v-else
              @click.stop="clickDanmu"
              class="iconfont icon-chat-close danmu-icon"
            ></span>
            <span style="font-size: 13px">弹幕</span>
          </div>
        </div>
        <div class="bar-rg">
          <span
            v-if="!isMute"
            @click.stop="clickMute"
            class="iconfont icon-mute1 video-mutes"
          ></span>
          <span
            v-else
            @click.stop="clickMute"
            class="iconfont icon-mute2 video-mutes"
          ></span>
          <div class="video-hd" @click.stop="clickQuality">
            <span>{{ qualityText }}</span>
          </div>
          <span
            v-if="!isFullscreens"
            @click.stop="clickFullscreen"
            class="iconfont icon-fullscreen1 video-fullscreen"
          ></span>
          <span
            v-else
            @click.stop="exitFullscreen"
            class="iconfont icon-fullscreen-exit video-fullscreen"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- 
	openDanmu 打开关闭弹幕
	isPlay 播放暂停
	isHideBar 打开关闭控制栏
	isFullscreen 打开关闭全屏
 -->
<script>
export default {
  props: {
    openDanmu: {
      type: Boolean,
      default: false
    },
    isPlay: {
      type: Boolean,
      default: true
    },
    isHideBar: {
      type: Boolean,
      default: false
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    ishigh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false,
      isDanmu: false
    };
  },
  watch: {},
  components: {},
  computed: {
    isPlays() {
      return this.isPlay;
    },
    isDisplay() {
      let d = this.isHideBar ? 'none' : 'block';
      return d;
    },
    isMute() {
      return getApp().globalData.muted;
    },
    isFullscreens() {
      return this.isFullscreen;
    },
    qualityText() {
      return this.ishigh ? '标清' : '高清';
    },
    volume() {
      return getApp().globalData.volume;
    }
  },
  destroyed() {},
  mounted() {},
  methods: {
    // 播放
    clickStart() {
      this.$emit('play');
    },
    // 暂停
    clickPause() {
      this.$emit('pause');
    },
    // 弹幕
    clickDanmu() {
      this.$emit('danmu');
    },
    // 声音
    clickMute() {
      this.$emit('mute', this.isMute);
    },
    // 标清
    clickQuality() {
      this.$emit('quality');
    },
    // 全屏
    clickFullscreen() {
      this.$emit('fullscreen');
    },
    // 取消全屏
    exitFullscreen() {
      this.$emit('exitFullscreen');
    },
    // 取消静音
    notMute() {
      this.$emit('mute', true);
    }
  }
};
</script>

<style lang="less" scoped>
.ag-video-start {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 9999;
  text-align: center;
  line-height: 40px;
}
.ag-video-start .ag-play-icon {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.ag-pause-icon {
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}
.ag-video-bar {
  width: 100%;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 0;
  transform: translateZ(0);
  color: #fff;
  z-index: 9999;
}
.ag-video-bar .bar-lf {
  flex: 0.4;
  display: flex;
  color: #fff;
  align-items: center;
  padding-left: 10px;
}
.bar-lf .lf-line {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e60012;
  margin-right: 5px;
}
.bar-rg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0.6;
  padding-right: 10px;
  position: relative;
}
.bar-rg .video-mutes {
  font-size: 22px;
  margin-right: 15px;
  cursor: pointer;
}
.bar-rg .video-fullscreen {
  font-size: 23px;
  cursor: pointer;
}
.bar-rg .video-hd {
  height: 18px;
  line-height: 20px;
  padding: 0px 2px;
  background-color: #fff;
  color: #000000;
  font-size: 10px;
  border-radius: 2px;
  margin-right: 15px;
  cursor: pointer;
}
.danmu-item {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.danmu-item .danmu-icon {
  font-size: 23px;
  margin-right: 5px;
}
.video-mute {
  position: absolute;
  bottom: 35px;
  right: 60px;
  z-index: 99999;

  cursor: pointer;
}
.video-mute .text {
  font-size: 13px;
  background-color: rgba(255, 106, 51, 0.8);
  border: 1px solid #ff6633;
  padding: 3px;
  border-radius: 5px;
  color: #fff;
}

.video-mute .triangle {
  width: 0px; /*  宽高设置为0，很重要，否则达不到效果 */
  height: 0px;
  border: 5px solid #ff6633;
  border-bottom-color: transparent; /* 设置透明背景色 */
  border-left-color: transparent;
  border-right-color: transparent;
  margin: auto;
}

.volume-control {
  position: absolute;
  bottom: 30px;
  right: 97.5px;
  z-index: 99999;

  cursor: pointer;
}
</style>
