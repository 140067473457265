<template>
  <div class="ag-list-wrapper">
    <!-- 比赛 -->
    <div v-if="infoType == 4">
      <div class="radio-group-box" style="margin-bottom: 20px">
        <div
          class="radio-group-item"
          :class="{ 'select-radio': matchType == item.id }"
          v-for="item in matchList"
          :key="item.id"
          @click="changeMatchType(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="search-box">
        <el-form :inline="true" size="mini">
          <el-form-item label="联赛">
            <ag-select-remote
              v-model="searchForm2.matchId"
              :data="leagueMatchList"
              :remote-method="remoteMethodMatch"
              :loading="loading"
            ></ag-select-remote>
          </el-form-item>
          <el-form-item label="主队">
            <ag-select-remote
              v-model="searchForm2.teamNamea"
              :data="listTeamA"
              name="name"
              :remote-method="changeTeamA"
            ></ag-select-remote>
          </el-form-item>
          <el-form-item label="客队">
            <ag-select-remote
              v-model="searchForm2.teamNameb"
              :data="listTeamB"
              name="name"
              :remote-method="changeTeamB"
            ></ag-select-remote>
          </el-form-item>
          <el-form-item>
            <a class="query-button" @click="querySearch(4)">查询</a>
          </el-form-item>
          <el-form-item>
            <a
              class="query-button"
              style="background-color: #909399"
              @click="reset(4)"
              >重置</a
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="match-broadcast-wrapper" v-loading="loadingData">
        <el-table
          :data="liveMatchList"
          highlight-current-row
          ref="matchTable"
          @current-change="handleMatchChange"
        >
          <el-table-column
            type="index"
            label="序号"
            width="70"
          ></el-table-column>
          <el-table-column
            property="joinMap.match.nameAbbr"
            label="联赛"
          ></el-table-column>
          <el-table-column property="name" label="比赛状态">
            <template slot-scope="scope">
              {{ vars.livestatus[scope.row.islive].name }}
            </template>
          </el-table-column>
          <el-table-column property="teamNamea" label="主队"></el-table-column>
          <el-table-column property="teamNameb" label="客队"></el-table-column>
          <el-table-column
            property="beginTime"
            label="比赛开始时间"
          ></el-table-column>
          <el-table-column label="操作" width="90">
            <template slot-scope="scope">
              <a size="mini" @click="selectMatch(scope.row)">选择</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        small
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="matchForm.page"
        :page-size="matchForm.size"
        background
        layout="total,  prev, pager, next, sizes, jumper"
        :total="matchTotal"
      >
      </el-pagination>
    </div>

    <!-- 正在直播的主播 -->
    <div v-if="infoType == 3">
      <div class="radio-group-box" style="margin-bottom: 20px">
        <div
          class="radio-group-item"
          :class="{ 'select-radio': anthorType == item.id }"
          v-for="item in anthorList"
          :key="item.id"
          @click="changeAnthorType(item)"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="search-box">
        <el-form :inline="true" size="mini">
          <el-form-item label="主播">
            <el-input
              v-model="searchForm1.userNicename"
              placeholder="主播名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="联赛">
            <el-input
              v-model="searchForm1.nameAbbr"
              placeholder="联赛名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="主队">
            <el-input
              v-model="searchForm1.teamNamea"
              placeholder="主队名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="客队">
            <el-input
              v-model="searchForm1.teamNameb"
              placeholder="客队名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <a class="query-button" @click="querySearch(3)">查询</a>
          </el-form-item>
          <el-form-item>
            <a
              class="query-button"
              style="background-color: #909399"
              @click="reset(3)"
              >重置</a
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="live-broadcast-wrapper" v-loading="loadingData">
        <el-table
          :data="liveBroadcastList"
          highlight-current-row
          ref="liveTable"
          @current-change="handleLiveChange"
        >
          <el-table-column
            type="index"
            label="序号"
            width="70"
          ></el-table-column>
          <el-table-column
            property="lv.userNicename"
            label="主播"
          ></el-table-column>
          <el-table-column
            property="joinMap.match.nameAbbr"
            label="联赛"
          ></el-table-column>
          <el-table-column
            property="lv.teamNamea"
            label="主队"
          ></el-table-column>
          <el-table-column
            property="lv.teamNameb"
            label="客队"
          ></el-table-column>
          <el-table-column
            property="lv.beginTime"
            label="比赛开始时间"
          ></el-table-column>
          <el-table-column label="操作" width="90">
            <template slot-scope="scope">
              <a size="mini" @click="selectLiveBroad(scope.row)">选择</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/api';
import util from '@/config/util';
import vars from '@/config/vars';
export default {
  props: {
    type: {
      type: Number,
      default: undefined
    },
    selectMatchInfo: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectLiveInfo: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      vars,
      anthorList: [
        { id: 1, name: '直播中' },
        { id: 2, name: '未开播' }
      ],
      matchList: [
        { id: 1, name: '直播中' },
        { id: 2, name: '未开始' }
      ],
      matchForm1: {
        size: 10,
        page: 1,
        live: 1
      },
      matchForm2: {
        size: 10,
        page: 1,
        live: 0,
        status: 0
      },
      anthorType: 1,
      matchType: 1,
      liveBroadcastList1: [],
      liveBroadcastList2: [],
      loadingData: false,
      liveMatchList1: [],
      liveMatchList2: [],
      matchTotal1: 0,
      matchTotal2: 0,
      currentMatchRow: null,
      currentLiveRow: null,
      searchForm1: {},
      searchForm2: {},
      leagueMatchList: [],
      loading: false,
      listTeamA: [],
      listTeamB: [],
      pageTeamAForm: { size: 100, name: '' },
      pageTeamBForm: { size: 100, name: '' }
    };
  },
  watch: {},
  computed: {
    infoType() {
      return this.type;
    },
    liveBroadcastList() {
      let uname = this.searchForm1.userNicename;
      let nameAbbr = this.searchForm1.nameAbbr;
      let teamNamea = this.searchForm1.teamNamea;
      let teamNameb = this.searchForm1.teamNameb;
      let list =
        this.anthorType == 2
          ? this.liveBroadcastList2
          : this.liveBroadcastList1;

      if (list && list.length > 0) {
        for (let i in list) {
          let r = list[i];
          if (this.anthorType == 2) {
            r.lv = {
              userNicename:
                r.joinMap && r.joinMap.u && r.joinMap.u.userNicename,
              teamNamea: r.teamNamea,
              teamNameb: r.teamNameb,
              beginTime: r.beginTime,
              uid: r.joinMap && r.joinMap.u && r.joinMap.u.id,
              nameAbbr: r.joinMap && r.joinMap.match && r.joinMap.match.name
            };
          } else {
            r.lv = {
              userNicename:
                r.joinMap && r.joinMap.user && r.joinMap.user.userNicename,
              teamNamea:
                r.joinMap && r.joinMap.schedule && r.joinMap.schedule.teamNamea,
              teamNameb:
                r.joinMap && r.joinMap.schedule && r.joinMap.schedule.teamNameb,
              beginTime:
                r.joinMap && r.joinMap.schedule && r.joinMap.schedule.beginTime,
              uid: r.uid,
              nameAbbr: r.joinMap && r.joinMap.match && r.joinMap.match.name
            };
          }
        }
      }
      let list1 = list.filter((r) => {
        let name = r.lv && r.lv.userNicename;
        console.log(name.indexOf(uname) < 0);
        if (uname && name.indexOf(uname) < 0) {
          return false;
        }
        if (nameAbbr && r.lv && r.lv.nameAbbr.indexOf(nameAbbr) < 0) {
          return false;
        }
        if (teamNamea && r.lv && r.lv.teamNamea.indexOf(teamNamea) < 0) {
          return false;
        }
        if (teamNameb && r.lv && r.lv.teamNameb.indexOf(teamNameb) < 0) {
          return false;
        }
        return true;
      });
      return list1;
    },
    liveMatchList() {
      let list =
        this.matchType == 1 ? this.liveMatchList1 : this.liveMatchList2;
      return list;
    },
    matchForm() {
      let form = this.matchType == 1 ? this.matchForm1 : this.matchForm2;
      return form;
    },
    matchTotal() {
      let total = this.matchType == 1 ? this.matchTotal1 : this.matchTotal2;
      return total;
    }
  },
  methods: {
    changeTeamA(v) {
      if (!v || v.length < 2) {
        return;
      }
      this.pageTeamAForm.name = v;
      api
        .pageTeam(this.pageTeamAForm)
        .then((resp) => {
          this.listTeamA = resp.data.list;
        })
        .catch((err) => {
          util.message(err.message);
        });
    },
    changeTeamB(v) {
      if (!v || v.length < 2) {
        return;
      }
      this.pageTeamBForm.name = v;
      api
        .pageTeam(this.pageTeamBForm)
        .then((resp) => {
          this.listTeamB = resp.data.list;
        })
        .catch((err) => {
          util.message(err.message);
        });
    },
    remoteMethodMatch(name) {
      this.loading = true;
      api
        .pageMatch({ name })
        .then((resp) => {
          this.loading = false;
          this.leagueMatchList = resp.data.list;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    querySearch(t) {
      if (t == 3) {
        let n = this.anthorType;
        switch (n) {
          case 1:
            this.getListUsersLive();
            break;
          case 2:
            this.getLiveRequest();
            break;
        }
      } else {
        let m = this.matchType;
        switch (m) {
          case 1:
            this.matchForm1.matchId = this.searchForm2.matchId;
            this.matchForm1.teamNamea = this.searchForm2.teamNamea;
            this.matchForm1.teamNameb = this.searchForm2.teamNameb;
            this.getMatchLiveList();
            break;
          case 2:
            this.matchForm2.matchId = this.searchForm2.matchId;
            this.matchForm2.teamNamea = this.searchForm2.teamNamea;
            this.matchForm2.teamNameb = this.searchForm2.teamNameb;
            this.getMatchList();
            break;
        }
      }
    },
    reset(t) {
      if (t == 3) {
        this.searchForm1 = {};
        let n = this.anthorType;
        switch (n) {
          case 1:
            this.getListUsersLive();
            break;
          case 2:
            this.getLiveRequest();
            break;
        }
      } else {
        this.searchForm2 = {};
        let m = this.matchType;
        switch (m) {
          case 1:
            delete this.matchForm1.matchId;
            delete this.matchForm1.teamNamea;
            delete this.matchForm1.teamNameb;
            this.getMatchLiveList();
            break;
          case 2:
            delete this.matchForm2.matchId;
            delete this.matchForm2.teamNamea;
            delete this.matchForm2.teamNameb;
            this.getMatchList();
            break;
        }
      }
    },
    handleMatchChange(val) {
      this.currentMatchRow = val;
    },
    handleLiveChange(val) {
      this.currentLiveRow = val;
    },
    changeAnthorType(item) {
      let t = item.id;
      this.loadingData = false;
      if (t == 2) {
        if (!this.liveBroadcastList2 || !this.liveBroadcastList2.length) {
          this.getLiveRequest();
        }
      }
      if (t == this.anthorType) {
        switch (t) {
          case 1:
            this.getListUsersLive();
            break;
          case 2:
            this.getLiveRequest();
            break;
        }
      }
      this.anthorType = item.id;
      this.changeSelectLive();
    },
    changeMatchType(item) {
      let t = item.id;
      this.loadingData = false;
      // this.searchForm2 = {}
      if (t == 2) {
        if (!this.liveMatchList2 || !this.liveMatchList2.length) {
          this.matchForm2.page = 1;
          this.matchType = 2;
          this.getMatchList();
          return;
        }
      }
      if (t == this.matchType) {
        switch (t) {
          case 1:
            this.getMatchLiveList();
            break;
          case 2:
            this.getMatchList();
            break;
        }
      }
      this.matchType = item.id;
      this.changeSelectMatch();
    },
    getDataList() {
      if (this.type == 3) {
        if (!this.liveBroadcastList1 || !this.liveBroadcastList1.length) {
          this.getListUsersLive();
        }
        this.changeSelectLive();
      } else if (this.type == 4) {
        if (!this.liveMatchList1 || !this.liveMatchList1.length) {
          this.getMatchLiveList();
        }
        this.changeSelectMatch();
      }
    },
    changeSelectLive() {
      let info = this.selectLiveInfo;
      let item = this.liveBroadcastList.find((r) => r.uid == info.id);
      let refCurrent = this.$refs.liveTable;
      if (item) {
        if (refCurrent) {
          this.$refs.liveTable.setCurrentRow(item);
        }
      } else {
        if (refCurrent) {
          this.$refs.liveTable.setCurrentRow();
        }
      }
      console.log('====', info);
    },
    changeSelectMatch() {
      let info = this.selectMatchInfo;
      let item = this.liveMatchList.find((r) => r.id == info.id);
      let refCurrent = this.$refs.matchTable;
      if (item) {
        if (refCurrent) {
          this.$refs.matchTable.setCurrentRow(item);
        }
      } else {
        if (refCurrent) {
          this.$refs.matchTable.setCurrentRow();
        }
      }
      console.log('====', info);
    },
    // 已上播
    getListUsersLive() {
      this.loadingData = true;
      api
        .listUsersLive(this.searchForm1)
        .then((resp) => {
          this.loadingData = false;
          if (resp.data && resp.data.length) {
            this.liveBroadcastList1 = resp.data;
            this.changeSelectLive();
          }
        })
        .catch((err) => {
          this.loadingData = false;
          util.message(err.message);
        });
    },
    // 申请直播未上播
    getLiveRequest() {
      this.loadingData = true;
      api
        .listRequestSchedule(this.searchForm1)
        .then((resp) => {
          this.loadingData = false;
          if (resp.data && resp.data.length) {
            this.liveBroadcastList2 = resp.data;
            this.changeSelectLive();
          }
        })
        .catch((err) => {
          this.loadingData = false;
          util.message(err.message);
        });
    },
    handleCurrentChange(page) {
      if (this.matchType == 1) {
        this.matchForm1.page = page;
        this.getMatchLiveList();
      } else {
        this.matchForm2.page = page;
        this.getMatchList();
      }
    },
    handleSizeChange(size) {
      if (this.matchType == 1) {
        this.matchForm1.size = size;
        this.getMatchLiveList();
      } else {
        this.matchForm2.size = size;
        this.getMatchList();
      }
    },
    // 直播中比赛
    getMatchLiveList() {
      this.loadingData = true;
      api
        .pageMatchSchedule(this.matchForm)
        .then((resp) => {
          this.loadingData = false;
          this.matchTotal1 = resp.data.total;
          if (resp.data && resp.data.list && resp.data.list.length) {
            this.liveMatchList1 = resp.data.list;
            this.changeSelectMatch();
          } else {
            this.liveMatchList1 = [];
          }
        })
        .catch((err) => {
          util.message(err.message);
        });
    },
    // 未开始比赛
    getMatchList() {
      this.loadingData = true;
      api
        .pageMatchSchedule(this.matchForm)
        .then((resp) => {
          this.loadingData = false;
          this.matchTotal2 = resp.data.total;
          if (resp.data && resp.data.list && resp.data.list.length) {
            this.liveMatchList2 = resp.data.list;
            this.changeSelectMatch();
          } else {
            this.liveMatchList2 = [];
          }
        })
        .catch((err) => {
          util.message(err.message);
        });
    },
    // 选择主播
    selectLiveBroad(row) {
      this.$emit('selectChange', row, this.anthorType);
    },
    selectMatch(row) {
      this.$emit('selectChange', row);
    }
  }
};
</script>
<style scoped></style>
